import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useConnect } from "wagmi";
import Web3 from "web3";
import { Dispute } from "../contractIntreaction";
import transaction from "../img/BlankScreenIcons/transaction.svg";
import TooltipWrapper from "./TooltipWrapper";

import OrderActionBtn from "../utils/common";
export default function Table() {
  const userId = localStorage.getItem("login");
  const auth = localStorage.getItem("auth");
  const user_type = localStorage.getItem("type");
  const dispatch = useDispatch();

  const [fileDispute, setFileDispute] = useState({
    showModal: false,
    reason: "",
    message: "",
  });

  const [showDisputeModal, setShowDisputeModal] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [errorMsg, setError] = useState("");
  const [currentOrderID, setCurrentOrderID] = useState("");
  const [isBuyer, setIsBuyer] = useState();
  const [showFulfillModal, setShowFullfillModal] = useState(false);
  const user = useSelector((state) => state.user);
  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const connectAndSign = async (x) => {
    await connect(x);
  };

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType === "Buyer") setIsBuyer(true);
    else setIsBuyer(false);
  };

  useEffect(() => {
    checkType();
  }, []);

  const getTransactionPackages = async () => {
    if (userId) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/transactions?userID=${userId}`,
        headers: {
          "Content-Type": "application/json",
          // "Authorization": token
        },
      })
        .then(async (response) => {
          if (response.data.data) await setTransactions(response.data.data);
        })
        .catch(async (error) => {
          console.log("error ===", error.response);
        });

      await axios
        .get(
          `https://web3backend.meinstein.ai/api/payment/${user_type.toLowerCase()}/get_with_paginate?limit=100&currentPage=1`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth}`,
            },
          }
        )
        .then(async (response) => {
          console.log("response====", response);
          if (response?.data?.data?.data)
            await setTransactions([
              ...transactions,
              ...response.data.data.data,
            ]);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const makeDisputeOnOrder = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios
      .post(
        `https://web3backend.meinstein.ai/api/payment/${user_type.toLowerCase()}/disputeOrder`,
        {
          orderId: fileDispute?.orderId,
          reason: fileDispute.reason,
          message: fileDispute.message,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.code === 200) {
          alert("Dispute raised successfully");

          setFileDispute({
            ...fileDispute,
            showModal: false,
            message: "",
            reason: "",
          });
        }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        alert(
          "Sorry, We are unable to proceed your request. Please try again later. If issue persist for long, please contact support."
        );
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getTransactionPackages();
  }, []);

  const handleFulfillOrder = async (data) => {
    const payload = {
      orderId: data?.orderId,
      signature: data?.signature,
      order_details: {
        ["Test"]: "ABC",
        ["Test2"]: "DEF",
        ["Test3"]: "GHI",
      },
    };

    await axios
      .post(
        `https://web3backend.meinstein.ai/api/payment/${user_type.toLowerCase()}/fulfillOrder`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then(async (response) => {
        alert("Order Fulfill Successfully");
      })
      .catch(async (error) => {
        console.log(error.response);
      })
      .finally(setShowDisputeModal(false));
  };

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Hey {user}! <span className="text-slate-500 font-normal">|</span> Data{" "}
          {isBuyer ? "Buyer" : "Seller"}
        </p>
        <div className="flex items-center gap-2 mt-6 ml-2">
          <h2 className="font-bold lg:text-[34px] max-[375px]:text-[20px] text-[24px] lg:text-left text-center tracking-[-0.02em] text-[#2B3674] dark:text-white   ">
            {isBuyer ? "Transactions" : "Orders"}
          </h2>
          <TooltipWrapper
            id={isBuyer ? "transactions" : "orders"}
            data={isBuyer ? "transaction" : "orders"}
            type={isBuyer ? "buyer" : "seller"}
          ></TooltipWrapper>
        </div>
        <div className="bg-white dark:bg-dark-container w-full rounded-[20px] px-8 py-5 mt-10 mb-20">
          <div className="mt-5 overflow-x-auto">
            <div className="border-b border-[#E9EDF7] flex items-center justify-between pb-2">
              <div className="min-w-[840px]">
                <div className="w-full flex justify-around text-[#A3AED0] tracking-[-0.02em] leading-6 text-sm font-medium ml-3">
                  <div className="flex items-center">
                    <p>Type</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Order ID </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Price (USD) </p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Fees (USD)</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Status</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>

                  <div className="flex items-center">
                    <p>Date</p>
                    <FiChevronDown className="text-lg ml-2" />
                  </div>
                  <div />
                </div>
              </div>
            </div>
            <div className="w-[1050px]">
              <table className="table-fixed border-separate border-spacing-y-2 w-full pl-10">
                <tbody className="text-[#2B3674] dark:text-white tracking-[-0.02em] leading-6 text-sm font-bold text-left ">
                  {transactions.length ? (
                    <>
                      {transactions.map((data, index) => (
                        <tr key={index} className="flex">
                          <td className="w-[11%]">
                            {/* Need to verify this condition with the response
                            for smart contract orders, as of now not receiving
                            any response and the values are undefined, so
                            commenting it out */
                            /* {data.seller === address ? "SELL" : ""}
                            {data.buyer === address ? "BUY" : ""} */}
                            {data.type === "seller" ? "SELL" : "BUY"}
                          </td>
                          <td className="w-[14%]">
                            {data.order_id || data.orderId}
                          </td>
                          <td className="w-[15%]">{data.price / 1e18}</td>
                          <td className="w-[14.5%]">
                            {Web3.utils.fromWei(data.fee.toString(), "ether")}
                          </td>
                          <td className="w-[12%]">{data.status}</td>
                          <td className="w-[16%]">
                            {data?.updatedAt?.split("T")[0] ||
                              data?.updated_at?.split("T")[0]}
                          </td>
                          <td className="w-[10%] space-x-2">
                            <a
                              href={`https://testnet.bscscan.com/tx/${data.trx}`}
                              target="_blank"
                              className="flex bg-dark-bg text-white font-medium h-[30px] items-center justify-center cursor-pointer w-[93px] rounded-full"
                              rel="noreferrer"
                            >
                              Detail
                            </a>
                          </td>
                          {!isBuyer && (
                            <td>
                              <OrderActionBtn
                                status={data?.status}
                                payload={{
                                  orderId: fileDispute?.orderId,
                                  reason: fileDispute.reason,
                                  message: fileDispute.message,
                                }}
                                onClick={() => {
                                  switch (data?.status) {
                                    case "pending":
                                      alert(
                                        "Please fulfill order from mobile app."
                                      );
                                      break;

                                    case "completed":
                                      setFileDispute({
                                        ...fileDispute,
                                        showModal: true,
                                        orderId: data?.orderId,
                                      });
                                      break;

                                    case "dispute":
                                      alert(
                                        "Need to call api for resolving dispute"
                                      );
                                      break;

                                    default:
                                      return <></>;
                                  }
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="mx-auto">
                        <p className="text-center mt-2 mb-5 font-thin  text-xl text-[#7114D2] dark:text-white ">
                          {" "}
                          No Transaction Found
                        </p>
                        <img
                          src={transaction}
                          alt="orderDataBlank"
                          className="mx-auto"
                        />
                      </div>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showDisputeModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modalOuter">
            <div className="relative w-auto mx-auto max-w-sm">
              <div className="p-3 border-0 w-[350px] text-black font-saira rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg mb-0 font-semibold text-center w-full">
                    {showFulfillModal ? "Attention Please!" : "Dispute"}
                  </h3>
                </div>
                <div className="relative flex-auto my-8">
                  {isConnected ? (
                    <div className="flex justify-center">
                      <Dispute orderId={currentOrderID} />
                    </div>
                  ) : showFulfillModal ? (
                    <>
                      <p className="text-sm text-center font-semibold">
                        Are you sure you want to fulfill this order?
                      </p>
                      <div className=" flex justify-end"></div>
                    </>
                  ) : (
                    <div className="flex justify-center items-center">
                      <>
                        {connectors
                          .filter((x) => x.ready && x.id !== connector?.id)
                          .map((x) => (
                            <>
                              {x.name === "MetaMask" ? (
                                <button
                                  type="button"
                                  className="bg-[#11047A] text-white font-medium h-[30px] px-5 rounded-full"
                                  key={x.id}
                                  onClick={async () => {
                                    await connectAndSign({ connector: x });
                                  }}
                                >
                                  Connect MetaMask
                                  {isLoading &&
                                    x.id === pendingConnector?.id &&
                                    " (connecting)"}
                                </button>
                              ) : null}
                            </>
                          ))}
                        {error ? (
                          <p className="text-sm text-center my-3 text-red-700 font-semibold">
                            {error}
                          </p>
                        ) : null}
                        {connectors.filter(
                          (x) => x.ready && x.id !== connector?.id
                        ).length ? null : (
                          <p className="text-sm text-center text-red-700 font-semibold">
                            <a
                              className="text-[#856DA7]"
                              href="https://metamask.io/download/"
                              target="_blank"
                            >
                              Install Metamask
                            </a>
                          </p>
                        )}
                      </>
                    </div>
                  )}
                </div>
                <div className="flex items-center justify-end">
                  <button
                    className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-md text-sm px-5 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    onClick={() => {
                      handleFulfillOrder(showFulfillModal);
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {fileDispute.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-2/6	 my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg p-4 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between pb-4">
                  <h3 className="text-l font-semibold">Create Dispute</h3>
                </div>
                <div className="relative">
                  <div>
                    <label
                      for="category"
                      class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Reason
                    </label>
                    <select
                      id="category"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      onChange={(e) =>
                        setFileDispute({
                          ...fileDispute,
                          reason: e.target.value,
                        })
                      }
                    >
                      <option selected="">Select Reason</option>
                      <option value="1">
                        Insufficient information provided
                      </option>
                      <option value="2">Invalid buyer</option>
                      <option value="3">Policy violation</option>
                    </select>
                  </div>
                  <div className="mt-3">
                    <label
                      for="description"
                      class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      rows="4"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write dispute description here"
                      onChange={(e) =>
                        setFileDispute({
                          ...fileDispute,
                          message: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-8">
                  <button
                    className="background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      setFileDispute({
                        ...fileDispute,
                        showModal: false,
                        message: "",
                        reason: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => makeDisputeOnOrder()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
