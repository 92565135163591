import React, { useEffect, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { tooltipComponent } from "../data/tooltipData";
// https://react-tooltip.com/docs/getting-started
// for updates/chnages here's the doc link
function TooltipWrapper({ id, data, type, fontSize = "text-xl" }) {
  const [tooltipPlace, setTooltipPlace] = useState(
    window.innerWidth < 768 ? "bottom" : "bottom-start"
  );

  useEffect(() => {
    const handleResize = () => {
      setTooltipPlace(window.innerWidth < 768 ? "bottom" : "bottom-start");
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <h6>
        <IoIosInformationCircleOutline
          className={`${fontSize} hover:cursor-pointer dark:text-white`}
          data-tooltip-id={id}
        />
      </h6>

      <Tooltip
        id={id}
        place={tooltipPlace}
        variant={"dark"}
        content={tooltipComponent[type][data]}
        style={{ fontSize: "14px" }}
        className=" max-w-96 max-[769px]:max-w-64 z-30 clear-css  text-pretty  text-ellipsis  "
      />
    </>
  );
}
export default TooltipWrapper;
