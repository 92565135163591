import React from "react";
import EditContract from "../components/EditContract";
import SideBar from "../components/SideBar";

export default function OrderEdit() {
  return (
    <>
      <div className="font-DMSans flex justify-end max-w-[1920px] mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-dark-bg">
          <EditContract />
        </div>
      </div>
    </>
  );
}
