export const tooltipComponent = {
  buyer: {
    dashboard:
      "Your control center for managing purchases and tracking transaction activity.The Dashboard offers a quick overview of all relevant activity, including recent orders, notifications, and shortcuts to important features. It’s the ideal starting point for buyers to get a summary of recent transactions, account updates, and quick access to key functions.",

    contractBuyer:
      "Start a new purchase agreement with clear, customizable terms.As a buyer, this section allows you to initiate new data purchases by creating contracts with terms you define. You can specify data requirements, access limits, and payment terms directly, providing you with control over your data acquisition process.",
    orders:
      "View and manage all data purchases you’ve made in one place.This section provides a list of all your data orders, active and completed. Here, you can monitor order status, view data availability, download completed orders, and track the delivery of purchased datasets—helping you manage all of your acquisitions seamlessly.",
    transaction:
      "Track all payments and financial transactions made within the marketplace.The Transactions section gives you an overview of all your monetary activities on the platform. For buyers, this feature is crucial for keeping track of your spending, viewing any pending transactions, and managing your purchasing budget.",
    dataContract:
      "Access detailed records of all active data contracts you’ve entered.This section lists all active data contracts, providing access to the specific terms, usage conditions, and duration for each agreement. It’s a valuable reference for tracking and managing ongoing contracts with data providers, ensuring you’re always aligned with the agreed-upon conditions.",
    disputes:
      "Resolve issues transparently if a transaction or data usage doesn’t meet expectations.If a dispute arises over data quality, access, or transaction terms, this section enables you to file, view, and manage disputes directly. It’s designed to protect buyers by providing a structured approach to resolving issues, ensuring accountability and satisfaction.",
    globalContract:
      "Explore community-wide data contracts and access standardized agreements.This section offers marketplace-wide contract templates or community-wide data offerings, providing an easy way to participate in larger data-sharing initiatives. For buyers, this feature simplifies the purchasing process with pre-defined contracts, ensuring a smooth, consistent experience.",
    editContract: "Modify the contnent if any change is required!",
  },
  seller: {
    dashboard:
      "Your hub for managing your data offerings and tracking sales.The Dashboard provides a snapshot of your activity on the marketplace, including recent sales, data package performance, and notifications. Sellers can get an overview of their listings, check pending orders, and monitor their earnings, making it the ideal place to manage daily activities.",
    orders:
      "Keep track of incoming orders and buyer interactions.This section lists all incoming and fulfilled orders. Sellers can monitor the status of each order, see buyer information, and view transaction details. It helps sellers stay on top of demand, manage responses, and ensure timely fulfillment of all orders.",
    dataPackages:
      "Manage and configure your data offerings.Here, sellers can create, modify, and track the performance of their data packages. This feature allows you to set descriptions, pricing, and access restrictions, as well as monitor which packages are gaining traction. It’s a comprehensive tool to ensure your data offerings are well-organized and appealing to buyers.",
    disputes:
      "Address and resolve any buyer concerns or issues efficiently.In case a buyer raises concerns about a data package, this section provides a transparent, structured process for managing disputes. Sellers can review dispute details, communicate with buyers, and work toward resolution to maintain high standards and customer trust.",
    globalContract:
      "Engage in marketplace-wide contracts for greater visibility and reach.This section includes shared contract templates or community-wide data initiatives, allowing sellers to participate in larger data-sharing agreements. It offers a way to reach a wider audience through standardized contracts, simplifying the process of making data available to multiple buyers.",
  },
  createContract: {
    dataRetaintionWindow:
      "Indicates how long purchased data will remain accessible for your use. The retention period allows you to plan and fully utilize the data within the available timeframe before access expires.",
    dataRetentionTimeframe:
      "Specifies the length of time that purchased data will remain available to you. This timeframe allows you to access and use the data within a set duration, after which it may no longer be accessible.",
    dataDeletionWindow:
      "Specifies the time frame within which the buyer must delete the purchased data from their systems once the contract expires, ensuring compliance with the agreement’s terms.",
    dataDeletionTimeframe:
      " Defines the required time in days, months or weeks for removing purchased data from the buyer’s systems after the contract expires, ensuring adherence to data management and compliance standards.",
    contractTerminationWindow:
      "Specifies the timeframe within which a contract can be revoked, allowing for flexibility and control over data agreements before they reach full term.",
    contractTerminationTimeframe:
      "Indicates the allowable timeframe (in days, weeks, months, or years) during which a contract may be revoked. This period provides flexibility for adjusting or ending agreements before their full completion.",
    deliveryWindow:
      "Specifies the timeframe within which the buyer expects to receive the data purchased from the seller as per the contract terms. This duration ensures timely access to data for buyer needs and planning.",
    deliveryTimeframe:
      "Indicates the expected timeframe (in days, weeks, months, or years)  for the buyer to receive the data purchased from the seller according to the contract terms. This period ensures timely data access to meet buyer requirements and project timelines.",
    disputeWindow:
      "Specifies the timeframe within which the buyer may raise a dispute if the purchased data does not meet the contractual standards. This period ensures that buyers have a clear window to address any concerns with the data quality or delivery.",
    disputeTimeframe:
      "Defines the timeframe in which buyers can raise a dispute if the data provided does not meet the agreed-upon standards. This period ensures buyers have an opportunity to address and resolve any issues within a set window..",
  },
};
