import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { FaRegWindowClose } from "react-icons/fa";
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { MdSpeed, MdVerifiedUser } from "react-icons/md";
import { RiFundsBoxLine, RiMoneyDollarCircleLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { Icons } from "../img/icons/iconsConfig";
import Logout from "./Logout";

import { FaExclamationCircle } from 'react-icons/fa';
export default function SideBar() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  const navigate = useNavigate();
  const location = useLocation();
  let loginUserAddress = localStorage.getItem("address");
  const [sidebar, setSidebar] = React.useState(false);
  const [isBuyer, setIsBuyer] = useState();
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const { address, connector, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { chains, errorCN, isLoadingCN, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const openSidebar = () => {
    setSidebar(!sidebar);
  };
  const [values, setValues] = React.useState({
    verifyAddress: false,
    verifyIdentity: false,
    verifyOrganization: false,
    verifyBank: false,
  });
  const [diroStatus, setDiroStatus] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const activeMenu = "flex items-center pl-[35.8px] mb-6 h-[30px] overflow-hidden text-ellipsis hover:text-[#856DA7]  whitespace-nowrap transition duration-300 ease-in-out text-[#7114D2] dark:text-[#7114D2] font-extrabold hover:opacity-60 hover:bg-[#FAFAFA]";

  const nonActiveMenu = "flex items-center pl-[35.8px] mb-6 h-[30px] overflow-hidden text-ellipsis whitespace-nowrap transition duration-300 ease-in-out text-[#969696] dark:text-[#969696] hover:bg-[#F0E5FF]";


  const logOut = async () => {
    await axios({
      method: "post",
      url: `https://web3backend.meinstein.ai/api/user/userLogout`,
      headers: {
        "Content-Type": "application/json",
        // "Authorization": token
      },
    })
      .then(async (response) => {
        localStorage.clear();
        navigate("/");

      })
    // .catch((error) => {

    // });
  };

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType == "Buyer") setIsBuyer(false);
    else setIsBuyer(true);
  };

  const getDiroStatus = async () => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/auth/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          console.log('data', response.data);
          if (response.data.data) {
            if (response.data.data.diroStatus === "pending") {
              setDiroStatus(false);
            }
            setEmail(response.data.data.email);
            await dispatch({ type: "user", payload: response.data.data.first_name });
          }
          await dispatch({ type: "loading", payload: false });

        })
        .catch(async (error) => {
          console.log('err', error.response);
          localStorage.clear();
          navigate("/");
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  const verfyDiro = async () => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "put",
        url: `https://web3backend.meinstein.ai/api/auth/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
        data: {
          is_diro_verified: 1,
        }
      })
        .then(async (response) => {
          console.log(response.data);
          setDiroStatus(true);
          setShowModal(true);
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  }

  const checkAddress = async () => {
    let userType = await localStorage.getItem("type");
    let userAddress = await localStorage.getItem("address");
    if (address) {
      if (userType == "Seller") {
        if (userAddress == "") {
          setErrorMsg("You don't have any wallet address associated with your account");
          disconnect();
        }
      }
      if (userType == "Buyer") {
        if (userAddress == "") {
          if (auth) {
            await dispatch({ type: "loading", payload: true });
            await axios({
              method: "put",
              url: `https://web3backend.meinstein.ai/api/auth/connect_meta_mask`,
              data: {
                signature: "signature",
                userAddress: address,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: auth,
              },
            })
              .then(async (response) => {
                setSuccessMsg("Wallet update succesfully")
                await dispatch({ type: "loading", payload: false });
              })
              .catch(async (error) => {
                console.log(error.response);
                await dispatch({ type: "loading", payload: false });
              });
          }
        }
      }
      if (userAddress && address != userAddress) {
        setErrorMsg("You don't have any wallet address associated with your account");
        disconnect();
      }
      setTimeout(() => {
        setErrorMsg("");
      }, 5000)
    }
  }

  useEffect(() => {
    setDatainSession();
  }, [location.search]);

  useEffect(() => {
    getDatafromSession();
  }, []);

  const getDatafromSession = () => {
    let sessionverifiedAddress = sessionStorage.getItem("verifyAddress");
    let sessionverifiedName = sessionStorage.getItem("verifyOrganization");
    let sessionverifiedIdentity = sessionStorage.getItem("verifyIdentity");
    let sessionverifiedBank = sessionStorage.getItem("verifyBank");
    setValues({
      ...values,
      verifyAddress: sessionverifiedAddress ? sessionverifiedAddress : "",
      verifyOrganization: sessionverifiedName ? sessionverifiedName : "",
      verifyIdentity: sessionverifiedIdentity ? sessionverifiedIdentity : "",
      verifyBank: sessionverifiedBank ? sessionverifiedBank : "",
    });
  };
  const setDatainSession = async () => {
    const queryParams = new URLSearchParams(location.search);
    const va = await queryParams.get("va");
    const vb = await queryParams.get("vb");
    const vo = await queryParams.get("vo");
    const vi = await queryParams.get("vi");

    if (va) {
      await sessionStorage.setItem("verifyAddress", va);
      setShowModal(true);
    }
    if (vo) {
      await sessionStorage.setItem("verifyOrganization", vo);
      setShowModal(true);
    }
    if (vi) {
      await sessionStorage.setItem("verifyIdentity", vi);
      setShowModal(true);
    }
    if (vb) {
      await sessionStorage.setItem("verifyBank", vb);
      setShowModal(true);
    }

    await getDatafromSession();
  };

  useEffect(() => {
    checkType();
    getDiroStatus();

  }, []);

  useEffect(() => {
    checkAddress();
  }, [address]);
  return (
    <>
      <div className="absolute top-7 left-6 z-40">
        <HiOutlineBars3CenterLeft
          size={30}
          className="text-[#2B3674] dark:text-white"
          onClick={openSidebar}
        />
      </div>
      <div
        className={`w-full max-h-screen fixed top-0 left-0 z-40 md:w-[40%] min-[920px]:w-[25%] xl:w-[20%] 2xl:w-[15%] overflow-y-auto ${sidebar ? "" : "hidden lg:block"
          }`}
      >
        <div className="min-h-screen  min-w-max   shadow-md bg-white px-1 dark:bg-dark-container">
          <div className="flex justify-end pt-6 mx-4 min-[920px]:hidden xl:hidden 2xl:hidden">
            <FaRegWindowClose
              size={25}
              className="text-[#2B3674] dark:text-white"
              onClick={openSidebar}
            />
          </div>
          <h3 className="text-[26px] flex flex-col items-center text-center text-[#2B3674] dark:text-white pt-5 font-Poppins font-bold leading-[26px]">
            <Link to="/dashboard">
              <div className="flex flex-col-reverse dark:flex-col">
                <img
                  src="/img/logo.jpg"
                  className="w-[120px] mb-3 dark:-mt-36 dark:hidden"
                  alt=""
                />
                <img
                  src="/img/darkLogo.png"
                  className="w-[120px]   invisible dark:visible -mt-32 dark:mt-1 mb-3"
                  alt=""
                />
              </div>
            </Link>
            mE Marketplace
            <>
              {!isConnected ? (
                <>
                  {connectors
                    .filter((x) => x.ready && x.id !== connector?.id)
                    .map((x) => (
                      <>
                        {x.name == "MetaMask" ? (
                          <button
                            type="button"
                            className="flex justify-center items-center px-8 tracking-[-0.02em] font-bold text-white bg-[#11047A] rounded-full py-2 sm:my-5 text-sm"
                            key={x.id}
                            onClick={async () => {
                              await connect({
                                connector: x,
                              });
                            }}
                          >
                            Connect MetaMask
                            {isLoading &&
                              x.id === pendingConnector?.id &&
                              " (connecting)"}
                          </button>
                        ) : null}
                      </>
                    ))}
                  {connectors.filter((x) => x.ready && x.id !== connector?.id)
                    .length ? null : (
                    <>
                      <p className="text-sm text-center text-red-700 font-semibold">
                        <a
                          className="text-[#856DA7]"
                          href="https://metamask.io/download/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          Install Metamask
                        </a>
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  {switchNetwork && chain?.id != 97 ? (
                    <div className="button-container mr-4">
                      {chains.map((x) =>
                        x.id === 97 ? (
                          <button
                            key={x.id}
                            className="flex justify-center items-center px-8 tracking-[-0.02em] font-bold text-wrap text-white bg-[#11047A] rounded-full py-2 sm:my-5 text-sm"
                            onClick={() => switchNetwork(x.id)}
                          >
                            Switch to BSC Testnet
                          </button>
                        ) : null
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="text-sm text-center text-[#856DA7] dark:text-white font-semibold mt-4">
                        {address ? address.slice(0, 8) : ""} ......{" "}
                        {address ? address.slice(-8) : ""}
                      </p>
                      <button
                        type="button"
                        className="flex justify-center items-center px-8 tracking-[-0.02em] font-bold text-white bg-red-700 rounded-full py-2 sm:my-5 text-sm"
                        onClick={disconnect}
                      >
                        Disconnect
                      </button>
                    </>
                  )}
                </>
              )}
            </>
            {!isBuyer
              ? <>
                {!diroStatus
                  ? <>
                    <div className="flex items-center my-2 gap-1"><button
                      type="button"
                      className="flex justify-center items-center px-4 tracking-widest font-thin text-white bg-[#E53535] rounded-full py-2 text-xs"
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >DIRO Verification</button>
                      <FaExclamationCircle className=" text-[#E53535] " /></div>
                  </>
                  : <div className="flex items-center my-2 gap-1"><button
                    type="button"
                    className="flex justify-center items-center px-4 tracking-widest font-thin text-white bg-[#05A660] rounded-full py-2 text-xs"
                  // onClick={() => {
                  //   setShowModal(true)
                  // }}
                  >DIRO Verified</button>
                    <MdVerifiedUser className=" text-[#05A660] " /></div>
                }
              </>
              : null
            }
          </h3>

          <div className="border border-[#F4F7FE] dark:border-[0.5px] my-5"></div>
          <ul className="relative text-[#A3AED0] font-medium tracking-[-0.02em]">
            <li className="relative ">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? activeMenu
                    : nonActiveMenu
                }
                id="item-1"
              >
                <MdSpeed className="text-[18.66px]" />
                <span className=" ml-[14.8px]">
                  Dashboard
                </span>
              </NavLink>
            </li>
            {!isBuyer ? (
              <li>
                <NavLink
                  to="/orderCreation"
                  className={({ isActive }) =>
                    isActive
                      ? activeMenu
                      : nonActiveMenu
                  }
                >
                  <RiMoneyDollarCircleLine className="text-xl" />
                  <span className="ml-[14.8px]">Create Contract</span>
                </NavLink>
              </li>
            ) : null}
            {!isBuyer ? (
              <li>
                <NavLink
                  to="/orderData"
                  className={({ isActive }) =>
                    isActive ? activeMenu : nonActiveMenu
                  }
                >
                  {({ isActive }) => (
                    <>
                      {isActive ? Icons.activeOrderData : Icons.orderData}
                      <span className="ml-[14.8px]">Orders Data</span>
                    </>
                  )}
                </NavLink>
              </li>
            ) : null}
            <li className="relative">
              <NavLink
                to={!isBuyer ? "/transactions" : "/orders"}
                className={({ isActive }) =>
                  isActive
                    ? activeMenu
                    : nonActiveMenu
                }
              >
                <RiFundsBoxLine className="text-[18.33px]" />
                <span className="ml-[14.8px]">{!isBuyer ? "Transactions" : "Orders"}</span>
              </NavLink>
            </li>
            <li className="relative">
              <NavLink
                to="/dataPackages"
                className={({ isActive }) =>
                  isActive
                    ? activeMenu
                    : nonActiveMenu
                }
              >
                {({ isActive }) => (
                  <>
                    <i className="hover:dark:fill-[#7114D2] hover:fill-slate-500">
                      {isActive ? Icons.activeDataContracts : Icons.dataContracts}
                    </i>
                    <span className="ml-[14.8px]">{!isBuyer ? "Data Contracts" : "Data Packages"}</span>
                  </>
                )}

              </NavLink>
            </li>
            <li className="relative">
              <NavLink
                to="/disputes"
                className={({ isActive }) =>
                  isActive
                    ? activeMenu
                    : nonActiveMenu
                }
              >
                {({ isActive }) => (
                  <>
                    {isActive ? Icons.activeDisputes : Icons.disputes}
                    <span className="ml-[14.8px]">Disputes </span>
                  </>)}
              </NavLink>
            </li>
            <li className="relative">
              <NavLink
                to="/globalContracts"
                className={({ isActive }) =>
                  isActive
                    ? activeMenu
                    : nonActiveMenu
                }
              >
                {({ isActive }) => (
                  <>
                    {isActive ? Icons.activeGlobalContracts : Icons.globalContracts}
                    <span className="ml-[14.8px]">Global Contracts</span>
                  </>
                )}
              </NavLink>
            </li>
            {/* {isBuyer ? (
              <li className="relative">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center pl-[35.8px] mb-6 h-[30px] overflow-hidden text-ellipsis whitespace-nowrap transition duration-300 ease-in-out activeNav"
                      : "flex items-center pl-[35.8px] mb-6 h-[30px] overflow-hidden text-ellipsis whitespace-nowrap transition duration-300 ease-in-out"
                  }
                  id="item-1"
                >
                  <FaUser className="text-base" />
                  <span className="ml-[14.8px]">Profile</span>
                </NavLink>
              </li>
            ) : null} */}
            <Logout />
          </ul>
        </div>
      </div>
      {errorMsg ? (
        <div class="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{errorMsg}</p>
        </div>
      ) : null}
      {successMsg ? (
        <div class="bg-red-600 fixed right-0 top-5 mx-2 text-white p-3 mt-2 rounded-lg">
          <p class="text-center">{successMsg}</p>
        </div>
      ) : null}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="border-0 w-[350px] rounded-lg shadow-lg relative flex flex-col bg-white dark:bg-[#2B3674] outline-none focus:outline-none">
                <div className="flex items-center justify-between py-2 px-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl mb-0 font-semibold">DIRO Verify</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <>
                    {email ? (
                      <>
                        {!values.verifyAddress ? (
                          <a
                            href={`https://verification.diro.io/?buttonid=O.US-KiS3rE-uh94&trackid=${email}`}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                          >
                            Verify Address
                          </a>
                        ) : (
                          <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                            <BsCheck2Circle
                              size={30}
                              className="mx-5"
                            />{" "}
                            Address Verified
                          </p>
                        )}
                        {!values.verifyOrganization ? (
                          <a
                            href={`https://verification.diro.io/?buttonid=O.US-KiS3rE-tj2b&trackid=${email}`}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                          >
                            Verify Organization
                          </a>
                        ) : (
                          <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                            <BsCheck2Circle
                              size={30}
                              className="mx-5"
                            />{" "}
                            Organization Verified
                          </p>
                        )}
                        {!values.verifyIdentity ? (
                          <a
                            href={`https://verification.diro.io/?buttonid=O.US-KiS3rE-tmOe&trackid=${email}`}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                          >
                            Verify Identity
                          </a>
                        ) : (
                          <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                            <BsCheck2Circle
                              size={30}
                              className="mx-5"
                            />{" "}
                            Identity Verified
                          </p>
                        )}
                        {!values.verifyBank ? (
                          <a
                            href={`https://verification.diro.io/?buttonid=O.US-KiS3rE-ISUg&trackid=${email}`}
                            className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                          >
                            Verify Bank
                          </a>
                        ) : (
                          <p className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-green-600 rounded-2xl py-2 sm:my-5">
                            <BsCheck2Circle
                              size={30}
                              className="mx-5"
                            />{" "}
                            Bank Verified
                          </p>
                        )}
                        {values.verifyAddress && values.verifyOrganization && values.verifyIdentity && values.verifyBank
                          ? <div className="w-full text-center text-xs sm:text-sm mt-10">
                            <button
                              type="button"
                              className="flex justify-center items-center w-full tracking-[-0.02em] font-bold text-white bg-[#856DA7] rounded-2xl py-5 sm:my-5"
                              onClick={verfyDiro}
                            >
                              Verify
                            </button>
                          </div>
                          : null
                        }
                      </>
                    ) : null}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
