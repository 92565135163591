import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "../data/countries";
import TooltipWrapper from "./TooltipWrapper";
export default function EditContract() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  const params = useParams();
  const { orderId } = params;
  const user = useSelector((state) => state.user);
  let dataTypes = {
    "Personal Data": {
      Basic: [
        "Email id",
        "First Name",
        "Last Name",
        "Phone Number",
        "Address",
        "Gender",
        "Marital Status",
        "Date Of Birth",
        "Anniversary",
        "Zip Code",
        "Country Code",
      ],
      Career: ["Employer", "Title", "Profession", "School"],
      Activities: ["Diets", "Sports", "Fitness", "Activities"],
      Social: [
        "Twitter Profile",
        "LinkedIn Profile",
        "Facebook Profile",
        "Instagram Profile",
      ],
    },
    "Finance Data": {
      Assets: ["Current Assets", "Fixed Assets"],
      Income: ["Active Income", "Passive Income", "Total Income"],
      Savings: ["Savings", "Start Age Earning", "Savings Ratio"],
      "Spending and Loans": [
        "Short Term Liabilities",
        "Long Term Liabilities",
        "General Monthly Expenses",
      ],
      "Credit Card and Wishlist": [
        "Total EMI",
        "Outstanding Amount",
        "WishList",
      ],
      Finance: [
        "Emergency Funds",
        "Net Worth",
        "Liquidity Ratio",
        "Debt to Assets Ratio",
        "Current Ratio",
        "Financial Freedom Ratio",
        "Debt Service Ratio",
        "Solvency Ratio",
        "Mortgage Limit",
        "Emergency Fund Limit",
        "Expense Ratio",
        "Emergency Fund Ratio",
      ],
    },
    "Home Care Data": {
      "My House": [
        "Property Name",
        "Move in date",
        "Address",
        "Postal Address",
        "Country",
        "Year Built",
        "Property Type",
        "Market Value",
        "Average Monthly Spend",
        "Home Equipment",
        "Home Appliance",
      ],
    },
    "Car Data": {
      "My Car Data": [
        "Car Id",
        "VIN Number",
        "Manufacturer Name",
        "Model Name",
        "Manufacturer Year",
        "Current Mileage",
        "Average Monthly Run",
        "Last Service Data",
        "Maintenance Item",
        "Cost",
      ],
    },
    "Travel Data": {
      "My Travel Profile": [
        "Trip Length",
        "Activities",
        "Interests",
        "Home Airport",
        "Travel Frequency",
        "Nearest Bus Stop",
        "Family Members",
        "Budget Preference",
        "Budget Per Person",
        "Home Railway Station",
      ],
    },
    "Assist Data": {},
    "Daily Routine Data": {
      "All Data": [
        "Duration in Min",
        "Tel No 1",
        "Longitude",
        "Place State",
        "Web Site",
        "Week Of The Year",
        "Year",
        "Tel No 2",
        "Fax No",
        "Month Of The Year",
        "Day Of The Month",
        "Place Zip Code",
        "Place Name",
        "Place Street Address",
        "Record Date",
        "Place Category",
        "Day Of The Week",
        "From Time",
        "Hours Of Operation",
        "To Time",
        "Day Of The Year",
        "Place Country",
        "Latitude",
      ],
    },
  };
  const [values, setValues] = React.useState({
    effectiveDate: "",
    companyName: "",
    address: "",
    country: "",
    contractDescription: "",
    DRDuration: "",
    DRPeriodType: "",
    DDDuration: "",
    DDPeriodType: "",
    CTDuration: "",
    CTPeriod: "",
    DeliveryDDuration: "",
    DeliveryDPeriodType: "",
    DPDuration: "",
    DPPeriod: "",
    ageRange: "",
    gender: "",
    minimum: "",
    maximum: "",
  });
  const [errorMsg, setError] = React.useState("");
  const [accordionState, setAccordionState] = React.useState({});
  const [selectedFilters, setSelectedFilters] = React.useState({});

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleAccordion = async (key) => {
    let allMainKeys = await Object.keys(dataTypes);

    if (allMainKeys.includes(key)) {
      await setAccordionState((prevState) => ({
        [key]: !prevState[key],
      }));
    } else {
      await setAccordionState((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }
  };

  const toggleFilter = async (category, subCategory, filter) => {
    const newFilters = { ...selectedFilters };

    if (!newFilters[category]) {
      newFilters[category] = {};
    }

    if (!newFilters[category][subCategory]) {
      newFilters[category][subCategory] = [];
    }

    const index = await newFilters[category][subCategory].indexOf(filter);

    if (index !== -1) {
      await newFilters[category][subCategory].splice(index, 1);
    } else {
      await newFilters[category][subCategory].push(filter);
    }
    await setSelectedFilters(newFilters);
  };

  const editContract = async (e) => {
    e.preventDefault();
    if (
      selectedFilters &&
      values.minimum &&
      values.maximum &&
      values.effectiveDate &&
      values.companyName &&
      values.address &&
      values.country &&
      values.contractDescription &&
      values.DRDuration &&
      values.DRPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.CTDuration &&
      values.CTPeriod &&
      values.DeliveryDDuration &&
      values.DeliveryDPeriodType &&
      values.DPDuration &&
      values.DPPeriod
    ) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "put",
        url: `https://web3backend.meinstein.ai/api/requested_data/${orderId}`,
        data: {
          category: Object.keys(selectedFilters),
          category_filter: {
            price: 1,
            status: "pending",
            selectedFilters: selectedFilters,
            deliveryDuration: values.DeliveryDDuration,
            deliveryPeriod: values.DeliveryDPeriodType,
            disputeDuration: values.DPDuration,
            disputePeriod: values.DPPeriod,
          },
          minPrice: values.minimum,
          maxPrice: values.maximum,
          effectiveDate: values.effectiveDate,
          buyerCompanyName: values.companyName,
          buyerAddress: values.address,
          buyerCountry: values.country,
          contractDescription: values.contractDescription,
          dataRetentionDuration: values.DRDuration,
          dataRetentionPeriod: values.DRPeriodType,
          dataDeletionDuration: values.DDDuration,
          dataDeletionPeriod: values.DDPeriodType,
          contractTerminationDuration: values.CTDuration,
          contractTerminationPeriod: values.CTPeriod,
          age: values.ageRange,
          gender: values.gender,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          if (response.data.success) await navigate("/dataPackages");
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: true });
        });
    } else setError("Please fill all the fields");
  };

  const getDataPackages = async () => {
    if (auth) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "get",
        url: `https://web3backend.meinstein.ai/api/requested_data/get_with_paginate`,
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          const currentContract = response.data.data.data.find(
            (contract) => contract.id == orderId
          );
          var dateObject = new Date(currentContract.effectiveDate);
          var year = dateObject.getFullYear();
          var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 to month because months are zero-indexed
          var day = ("0" + dateObject.getDate()).slice(-2);

          let effectiveDate = year + "-" + month + "-" + day;
          await setValues((prevState) => ({
            ...prevState,
            effectiveDate: effectiveDate,
            companyName: currentContract.buyerCompanyName,
            address: currentContract.buyerAddress,
            country: currentContract.buyerCountry,
            contractDescription: currentContract.contractDescription,
            DRDuration: currentContract.dataRetentionDuration,
            DRPeriodType: currentContract.dataRetentionPeriod,
            DDDuration: currentContract.dataDeletionDuration,
            DDPeriodType: currentContract.dataDeletionPeriod,
            CTDuration: currentContract.contractTerminationDuration,
            CTPeriod: currentContract.contractTerminationPeriod,
            ageRange: currentContract.age,
            gender: currentContract.gender,
            minimum: currentContract.minPrice,
            maximum: currentContract.maxPrice,
            DeliveryDDuration: currentContract.category_filter.deliveryDuration
              ? currentContract.category_filter.deliveryDuration
              : "",
            DeliveryDPeriodType: currentContract.category_filter.deliveryPeriod
              ? currentContract.category_filter.deliveryPeriod
              : "",
            DPDuration: currentContract.category_filter.disputeDuration
              ? currentContract.category_filter.disputeDuration
              : "",
            DPPeriod: currentContract.category_filter.disputePeriod
              ? currentContract.category_filter.disputePeriod
              : "",
          }));
          await setSelectedFilters(
            currentContract.category_filter.selectedFilters
          );
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          console.log(error.response);
          await dispatch({ type: "loading", payload: false });
        });
    }
  };

  useEffect(() => {
    getDataPackages();
  }, []);

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Hey {user}! <span className="text-slate-500 font-normal">|</span> Data
          Buyer
        </p>
        <div className="flex items-center gap-2 mt-6 ml-2">
          <h2 className="font-bold lg:text-[34px] max-[375px]:text-[20px] text-[24px] lg:text-left text-center tracking-[-0.02em] text-[#2B3674] dark:text-white   ">
            Edit Data Contract
          </h2>
          <TooltipWrapper
            id={"contractBuyer"}
            data={"editContract"}
            type={"buyer"}
          ></TooltipWrapper>
        </div>
        <div className="bg-white dark:bg-[#2B3674] shadow-[14px_17px_40px_4px_rgba(112,144,176,0.08)] my-5 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] min-h-[540px]">
          <div>
            <div className="tracking-[-0.02em] text-[#2B3674] dark:text-white mt-14">
              <div className="text-[12.5px] font-normal flex flex-wrap gap-y-3 gap-x-6">
                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Effective Date*</label>
                    <input
                      type="date"
                      name="effectiveDate"
                      onChange={handleChange}
                      value={values.effectiveDate}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Company Name*</label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={values.companyName}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Company Name"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Address*</label>
                    <input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Address"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Country*</label>
                    <select
                      name="country"
                      onChange={handleChange}
                      value={values.countryCode}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select your country
                      </option>
                      {countries.length &&
                        countries.map((data, index) => (
                          <option key={index} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Contract Description*</label>
                    <textarea
                      rows="5"
                      col="20"
                      name="contractDescription"
                      onChange={handleChange}
                      value={values.contractDescription}
                      className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Description"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Retention Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataRetaintionWindow"}
                          data={"dataRetaintionWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DRDuration"
                      onChange={handleChange}
                      value={values.DRDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Data Retention Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Retention Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataRetentionTimeframe"}
                          data={"dataRetentionTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DRPeriodType"
                      onChange={handleChange}
                      value={values.DRPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Deletion Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataDeletionWindow"}
                          data={"dataDeletionWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DDDuration"
                      onChange={handleChange}
                      value={values.DDDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Data Deletion Duration "
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Deletion Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataDeletionTimeframe"}
                          data={"dataDeletionTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DDPeriodType"
                      onChange={handleChange}
                      value={values.DDPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Contract Termination Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"contractTerminationWindow"}
                          data={"contractTerminationWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="CTDuration"
                      onChange={handleChange}
                      value={values.CTDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Termination Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Contract Termination Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"contractTerminationTimeframe"}
                          data={"contractTerminationTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="CTPeriod"
                      onChange={handleChange}
                      value={values.CTPeriod}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Delivery Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"deliveryWindow"}
                          data={"deliveryWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DeliveryDDuration"
                      onChange={handleChange}
                      value={values.DeliveryDDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Delivery Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Delivery Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"deliveryTimeframe"}
                          data={"deliveryTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DeliveryDPeriodType"
                      onChange={handleChange}
                      value={values.DeliveryDPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Dispute Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"disputeWindow"}
                          data={"disputeWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>

                    <input
                      type="number"
                      name="DPDuration"
                      onChange={handleChange}
                      value={values.DPDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Dispute Duration"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Dispute Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"disputeTimeframe"}
                          data={"disputeTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>

                    <select
                      name="DPPeriod"
                      onChange={handleChange}
                      value={values.DPPeriod}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-slate-700 bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">Data Types</p>

                <div className="flex flex-wrap cursor-pointer relative">
                  {Object.keys(dataTypes).map((category) => (
                    <div>
                      <div
                        key={category}
                        className={`inline-block ${
                          accordionState[category]
                            ? "text-[#11047A] border-2 border-[#11047A] py-1 font-semibold"
                            : "bg-slate-700 text-white py-1"
                        } rounded-[16px] font-medium leading-6 text-xs px-3 mb-3 mr-2`}
                      >
                        <h2
                          className="inline-block"
                          onClick={() => toggleAccordion(category)}
                        >
                          {category}
                        </h2>
                      </div>
                      <div className="mb-2 rounded-[16px] absolute z-50 bg-slate-700 w-[250px]">
                        {accordionState[category] && (
                          <div className="py-4 px-2">
                            {Object.keys(dataTypes[category]).map(
                              (subCategory) => (
                                <div
                                  key={subCategory}
                                  className="rounded-[16px] border-2 text-white bg-slate-500  border-[#f7f7f7] mb-1 px-4"
                                >
                                  <h3
                                    onClick={() => toggleAccordion(subCategory)}
                                  >
                                    {subCategory}
                                  </h3>
                                  <div className="ml-2">
                                    {accordionState[subCategory] && (
                                      <ul>
                                        {dataTypes[category][subCategory].map(
                                          (item) => (
                                            <li key={item}>
                                              <div className="flex items-center p-2 ">
                                                <input
                                                  id={`checkbox-item-${item}`}
                                                  type="checkbox"
                                                  checked={selectedFilters[
                                                    category
                                                  ]?.[subCategory]?.includes(
                                                    item
                                                  )}
                                                  onChange={() =>
                                                    toggleFilter(
                                                      category,
                                                      subCategory,
                                                      item
                                                    )
                                                  }
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                />
                                                <label
                                                  htmlFor={`checkbox-item-${item}`}
                                                  className="w-full ms-2 text-xs text-gray-200"
                                                >
                                                  {item}
                                                </label>
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">
                  User Demographics
                </p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Age Range*</label>
                      <select
                        name="ageRange"
                        onChange={handleChange}
                        value={values.ageRange}
                        className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Age Range
                        </option>
                        <option value="Teenagers">
                          Teenagers (13-17 years)
                        </option>
                        <option value="Young Adults">
                          Young Adults (18-24 years)
                        </option>
                        <option value="Adults">Adults (25-34 years)</option>
                        <option value="Young Professionals">
                          Young Professionals (25-44 years)
                        </option>
                        <option value="Middle-Aged Adults">
                          Middle-Aged Adults (35-54 years)
                        </option>
                        <option value="Seniors">Seniors (55-64 years)</option>
                        <option value="Elderly">Elderly (65 and above)</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Gender*</label>
                      <select
                        name="gender"
                        onChange={handleChange}
                        value={values.gender}
                        className="form-control w-full block px-3 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium ml-2 my-5">Price Range</p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Minimum*</label>
                      <input
                        type="number"
                        name="minimum"
                        onChange={handleChange}
                        value={values.minimum}
                        className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Minimum"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Maximum*</label>
                      <input
                        type="number"
                        name="maximum"
                        onChange={handleChange}
                        value={values.maximum}
                        className="form-control block w-full px-6 py-2 mt-2 rounded-xl text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-700 bg-clip-padding border border-solid border-gray-300 dark:border-none transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Maximum"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errorMsg ? (
                <p className="text-lg text-center text-red-700 font-semibold bg-red-50">
                  {errorMsg}
                </p>
              ) : null}

              <div className="flex justify-end">
                <button
                  className="min-w-max text-white bg-[#11047A] rounded-[47px] font-medium text-sm leading-6 my-3 px-7 py-1"
                  onClick={editContract}
                >
                  Edit Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
