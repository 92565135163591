import React from "react";
import { FaMoon } from "react-icons/fa";
// import { dashboard } from "../data/tooltipData";
import { useEffect, useState } from "react";
import TooltipWrapper from "./TooltipWrapper";

export default function SearchBar(props) {
  const [isBuyer, setIsBuyer] = useState();
  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType === "Buyer") setIsBuyer(false);
    else setIsBuyer(true);
  };
  useEffect(() => {
    checkType();
  }, []);
  return (
    <>
      <div className="flex flex-col xl:flex-row lg:justify-between lg:items-center mt-4 mb-[23px] space-y-5">
        <div className="flex items-center gap-2">
          <h2 className="font-bold lg:text-[34px] text-[24px] lg:text-left text-center leading-[42px] tracking-[-0.02em] text-[#2B3674] dark:text-white ml-2 ">
            {props.heading}
          </h2>
          <span>
            <TooltipWrapper
              id={props.heading.toLowerCase()}
              data={props.heading.toLowerCase()}
              type={!isBuyer ? "buyer" : "seller"}
            ></TooltipWrapper>
          </span>
        </div>

        <div
          className="flex flex-col md:flex-row md:items-center space-x-5 rounded-[30px] bg-white dark:bg-theme-purple shadow-lg px-[10px] cursor-pointer py-2"
          onClick={() => props.setThemeToggle(!props.themeToggle)}
        >
          <div className="flex items-center justify-between space-x-5 lg:py-0 py-2 px-5 ">
            <button>
              <FaMoon className="text-[#A3AED0] dark:text-white text-xl" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
