import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { RiLoginBoxLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { baseAPI } from '../data/constant';

const Logout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
      cancelButtonRef.current?.focus();
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await axios({
        method: "post",
        url: `${baseAPI}/user/userLogout`,
        headers: {
          "Content-Type": "application/json",
          // "Authorization": token
        },
      })
        .then(async (response) => {
          localStorage.clear();
          navigate("/");

        })

    } catch (error) {
      // console.error('Logout error:', error.response);
      setError('Failed to logout. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <li className="relative cursor-pointer pb-6">
        <a

          className="flex items-center cursor-pointer pl-[35.8px] h-[30px] text-[#969696] overflow-hidden hover:text-[#7114D2] hover:bg-[#F0E5FF] text-ellipsis whitespace-nowrap transition duration-300 ease-in-out hover:font-bold"
          onClick={() => setIsOpen(true)}
        >
          <RiLoginBoxLine className="text-xl" />
          <span className="ml-[14.8px]">Log Out</span>
        </a>
      </li>


      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          onClick={handleClickOutside}
          role="presentation"
        >
          <div
            ref={modalRef}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6 space-y-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            <div className="space-y-2">
              <h2
                id="modal-title"
                className="text-xl font-semibold text-gray-900 dark:text-gray-100"
              >
                Confirm Logout
              </h2>
              <p className="text-gray-500 dark:text-gray-400">
                Are you sure you want to logout?
              </p>
            </div>


            {error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <div className="flex space-x-4 justify-end">
              <button
                ref={cancelButtonRef}
                onClick={() => setIsOpen(false)}
                disabled={isLoading}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                disabled={isLoading}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#7114D2] border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <>
                    <svg
                      className="w-4 h-4 mr-2 animate-spin"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Logging out...
                  </>
                ) : (
                  "Logout"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Logout;
