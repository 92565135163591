import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function TopContracts(props) {
  const [isOpenAge, setIsOpenAge] = useState(false);
  const [isOpenGender, setIsOpenGender] = useState(false);
  const dropdownAgeRef = useRef(null);
  const dropdownGenderRef = useRef(null);
  const [filters, setFilters] = useState({
    age: [],
    gender: [],
    minPrice: "",
    maxPrice: "",
  });
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const ImageComponent = () => {
    const imageSrc = require(`../img/BlankScreenIcons/${props.blank}.svg`);
    return <img src={imageSrc} alt={props.blank} />;
  };
  const applyFilters = () => {
    const filteredContracts = props.contracts.filter((contract) => {
      const minPricePass =
        !filters.minPrice || contract.minPrice >= parseFloat(filters.minPrice);
      const maxPricePass =
        !filters.maxPrice || contract.maxPrice <= parseFloat(filters.maxPrice);
      const agePass =
        filters.age.length === 0 || filters.age.includes(contract.age);
      const genderPass =
        filters.gender.length === 0 || filters.gender.includes(contract.gender);

      return minPricePass && maxPricePass && agePass && genderPass;
    });

    return filteredContracts;
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFilters({
          ...filters,
          [name]: [...filters[name], value],
        });
      } else {
        setFilters({
          ...filters,
          [name]: filters[name].filter((data) => data !== value),
        });
      }
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownAgeRef.current &&
        !dropdownAgeRef.current.contains(event.target)
      ) {
        setIsOpenAge(false);
      }
      if (
        dropdownGenderRef.current &&
        !dropdownGenderRef.current.contains(event.target)
      ) {
        setIsOpenGender(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getAgeLabel = (value) => {
    const ageLabels = {
      Teenagers: "13-17 years",
      "Young Adults": "18-24 years",
      Adults: "25-34 years",
      "Young Professionals": "25-44 years",
      "Middle-Aged Adults": "35-54 years",
      Seniors: "55-64 years",
      Elderly: "65 and above",
    };
    return ageLabels[value] || value;
  };
  const getGenderLabel = (value) => {
    return value === "M" ? "Male" : "Female";
  };

  const removeAgeChip = (ageToRemove) => {
    const newAge = filters.age.filter((age) => age !== ageToRemove);
    handleFilterChange({
      target: {
        name: "age",
        value: newAge,
      },
    });
  };

  const removeGenderChip = (genderToRemove) => {
    const newGender = filters.gender.filter(
      (gender) => gender !== genderToRemove
    );
    handleFilterChange({
      target: {
        name: "gender",
        value: newGender,
      },
    });
  };
  return (
    <>
      <div className="flex  justify-center mt-6">
        <div className="w-full lg:w-[70%] bg-white dark:bg-slate-800 shadow-lg rounded-2xl p-4 space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="number"
              name="minPrice"
              ref={inputRef}
              value={filters.minPrice}
              onChange={handleFilterChange}
              placeholder="Min Price"
              className="w-full px-4 py-2.5 rounded-xl text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-800 transition-all outline-none placeholder:text-gray-500"
            />
            <input
              type="number"
              name="maxPrice"
              value={filters.maxPrice}
              onChange={handleFilterChange}
              placeholder="Max Price"
              className="w-full px-4 py-2.5 rounded-xl text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-800 transition-all outline-none placeholder:text-gray-500"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="space-y-2">
              <div ref={dropdownAgeRef} className="relative">
                <button
                  onClick={() => setIsOpenAge(!isOpenAge)}
                  className="w-full h-10 px-4 rounded-xl text-gray-500 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 hover:bg-gray-100 dark:hover:bg-slate-600 transition-all text-left flex justify-between items-center"
                >
                  <span className="text-gray-500">
                    {filters.age.length > 0
                      ? `${filters.age.length} selected`
                      : "Age"}
                  </span>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isOpenAge && (
                  <div className="absolute z-50 w-full mt-2 bg-white dark:bg-slate-800 rounded-xl shadow-lg border border-gray-200 dark:border-slate-600">
                    <ul className="py-2 px-3 space-y-1">
                      {[
                        { value: "Teenagers", label: "13-17 years" },
                        { value: "Young Adults", label: "18-24 years" },
                        { value: "Adults", label: "25-34 years" },
                        { value: "Young Professionals", label: "25-44 years" },
                        { value: "Middle-Aged Adults", label: "35-54 years" },
                        { value: "Seniors", label: "55-64 years" },
                        { value: "Elderly", label: "65 and above" },
                      ].map(({ value, label }) => (
                        <li
                          key={value}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 dark:hover:bg-slate-700 rounded-lg"
                        >
                          <input
                            className="w-4 h-4 mr-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            name="age"
                            onChange={handleFilterChange}
                            type="checkbox"
                            checked={filters.age.includes(value)}
                            value={value}
                          />
                          <span className="text-gray-700 dark:text-gray-200">
                            {label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {filters.age.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {filters.age.map((age) => (
                    <span
                      key={age}
                      className="inline-flex items-center px-2.5 py-1 rounded-lg text-sm bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200"
                    >
                      {getAgeLabel(age)}
                      <button
                        onClick={() => removeAgeChip(age)}
                        className="ml-1.5 text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
                      >
                        <svg
                          className="w-3.5 h-3.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className="space-y-2">
              <div ref={dropdownGenderRef} className="relative">
                <button
                  onClick={() => setIsOpenGender(!isOpenGender)}
                  className="w-full h-10 px-4 rounded-xl text-gray-500 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 hover:bg-gray-100 dark:hover:bg-slate-600 transition-all text-left flex justify-between items-center"
                >
                  <span className="text-gray-500">
                    {filters.gender.length > 0
                      ? `${filters.gender.length} selected`
                      : "Gender"}
                  </span>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isOpenGender && (
                  <div className="absolute z-50 w-full mt-2 bg-white dark:bg-slate-800 rounded-xl shadow-lg border border-gray-200 dark:border-slate-600">
                    <ul className="py-2 px-3 space-y-1">
                      {[
                        { value: "M", label: "Male" },
                        { value: "F", label: "Female" },
                      ].map(({ value, label }) => (
                        <li
                          key={value}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 dark:hover:bg-slate-700 rounded-lg"
                        >
                          <input
                            className="w-4 h-4 mr-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            name="gender"
                            onChange={handleFilterChange}
                            type="checkbox"
                            checked={filters.gender.includes(value)}
                            value={value}
                          />
                          <span className="text-gray-700 dark:text-gray-200">
                            {label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {filters.gender.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {filters.gender.map((gender) => (
                    <span
                      key={gender}
                      className="inline-flex items-center px-2.5 py-1 rounded-lg text-sm bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200"
                    >
                      {getGenderLabel(gender)}
                      <button
                        onClick={() => removeGenderChip(gender)}
                        className="ml-1.5 text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
                      >
                        <svg
                          className="w-3.5 h-3.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className="flex items-start lg:items-center sm:col-span-2 lg:col-span-1">
              <button className="w-full h-10 px-6 bg-theme-purple hover:bg-[#4b25b2] text-white font-medium rounded-xl transition-colors duration-200 flex items-center justify-center space-x-2 sm:w-auto sm:mx-auto md:w-full ">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap my-8">
        {applyFilters().length ? (
          applyFilters().map((data, index) => (
            <div className="lg:w-[25%] md:w-[50%] w-full">
              <Link
                to={`/contract/${data.orderDetails.order_id}`}
                key={index}
                className="bg-white dark:bg-[#2B3674] w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
              >
                <div class="relative w-full flex flex-wrap justify-between">
                  {data.category.length > 1 ? (
                    <img
                      className={`w-full h-full object-cover rounded-2xl `}
                      src={`/dataImages/Multi Data.jpg`}
                      alt="Slider Image"
                    />
                  ) : (
                    <img
                      className={`w-full h-full object-cover rounded-2xl `}
                      src={`/dataImages/${data.category[0]}.jpg`}
                      alt="Slider Image"
                    />
                  )}
                </div>

                <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                  <div>
                    <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                      {data.requestId}
                    </h4>
                    <p className="text-sm text-[#A3AED0]">
                      {data?.daysAgo} days ago
                    </p>
                    <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                      Created Date : {data.orderDetails.createdAt.split("T")[0]}
                    </p>
                    <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                      Listed Date : {data.createdAt.split("T")[0]}
                    </p>
                  </div>
                  <div>
                    <div className="w-24 inline-flex justify-end">
                      <p className="text-[#856DA7] text-center dark:text-blue-600 font-bold text-[13px] bg-[#e0e5f2] px-3 py-0 rounded-lg inline">
                        {data.age === "Teenagers" && "13-17 years"}
                        {data.age === "Young Adults" && "18-24 years"}
                        {data.age === "Adults" && "25-34 years"}
                        {data.age === "Young Professionals" && "25-44 years"}
                        {data.age === "Middle-Aged Adults" && "35-54 years"}
                        {data.age === "Seniors" && "55-64 years"}
                        {data.age === "Elderly" && "65 and above"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                  <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                    Price Range : ${data.minPrice} - ${data.maxPrice}
                  </p>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="mx-auto">
            <h5 className=" text-center  mb-1  text-xl text-[#7114D2] dark:text-white ">
              No Contracts Found
            </h5>
            <ImageComponent />
          </div>
        )}
      </div>
    </>
  );
}
