
import React, { useEffect, useState } from 'react';
import { BsArrowRightCircle as ArrowRightCircle } from "react-icons/bs";
import { FaChevronLeft as ChevronLeft } from "react-icons/fa";
import { MdCheckCircleOutline as CheckCircle2 } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import SideBar from "../components/SideBar";

const transactionsData = [
  {
    transactionId: "1",
    contractId: "C12345",
    contractDate: "2024-11-01",
    orderId: "O98765",
    orderType: "Purchase",
    orderDate: "2024-11-02",
    transactionDate: "2024-11-03",
    sellerFirstName: "John",
    sellerLastName: "Doe",
    size: "Large",
    fulfilledPrice: 200.5,
    status: "Completed",
    transactionStages: [
      { stage: "Payment Pending", date: "2024-11-01" },
      { stage: "Payment Confirmed", date: "2024-11-02" },
      { stage: "Shipped", date: "2024-11-02" },
      { stage: "Delivered", date: "2024-11-03" }
    ]
  },
  {
    transactionId: "2",
    contractId: "C12345",
    contractDate: "2024-11-01",
    orderId: "O98765",
    orderType: "Purchase",
    orderDate: "2024-11-02",
    transactionDate: "2024-11-03",
    sellerFirstName: "John",
    sellerLastName: "Doe",
    size: "Large",
    fulfilledPrice: 200.5,
    status: "Completed",
    transactionStages: [
      { stage: "Fullfiled", date: "2024-11-01" },
      { stage: "Payment Confirmed", date: "2024-11-02" },
      { stage: "In Dispute", date: "2024-11-02" },
      { stage: "Resolved", date: "2024-11-03" }
    ]
  },
  {
    transactionId: "3",
    contractId: "C12345",
    contractDate: "2024-11-01",
    orderId: "O98765",
    orderType: "Purchase",
    orderDate: "2024-11-02",
    transactionDate: "2024-11-03",
    sellerFirstName: "John",
    sellerLastName: "Doe",
    size: "Large",
    fulfilledPrice: 200.5,
    status: "Completed",
    transactionStages: [
      { stage: "Payment Pending", date: "2024-11-01" },
      { stage: "Payment Confirmed", date: "2024-11-02" },
      { stage: "Shipped", date: "2024-11-02" },
      { stage: "Delivered", date: "2024-11-03" }
    ]
  },

];

const TransactionDetail = () => {
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {

    const foundTransaction = transactionsData.find(
      (transaction) => transaction.transactionId === transactionId
    );
    setTransaction(foundTransaction);
  }, [transactionId]);

  if (!transaction) {
    return <div>Loading...</div>;
  }

  const {
    contractId,
    contractDate,
    orderId,
    orderType,
    orderDate,
    transactionDate,
    sellerFirstName,
    sellerLastName,
    size,
    fulfilledPrice,
    status,
    transactionStages
  } = transaction;

  return (

    <div className="font-DMSans flex justify-end mx-auto">
      <SideBar />

      <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-dark-bg">
        <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
          <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
            Hey {user}! <span className="text-slate-500 font-normal">|</span>{" "}
            Data Buyer
          </p>
          <div className="flex items-center gap-2 mt-6 ml-2">
            <h2 className="font-bold lg:text-[34px] max-[375px]:text-[20px] text-[24px] lg:text-left text-center tracking-[-0.02em] text-[#2B3674] dark:text-white">
              Transcation Details
            </h2>
          </div>


          <div className="bg-white dark:bg-dark-container my-5 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] mb-12">

            <div className="overflow-x-auto">
              <div className="flex flex-col gap-4 mb-8">
                <button
                  className="flex items-center gap-2 bg-slate-100 dark:bg-dark-bg p-2 pr-4 rounded-lg self-start"
                  onClick={() => window.history.back()}
                >
                  <ChevronLeft size={20} />
                  <span className="hidden sm:inline">Back</span>
                </button>


              </div>
              <div className="container overflow-x-aut mx-auto px-4">


                <table className="min-w-full table-auto  mb-6">
                  <thead>
                    <tr className="bg-gray-100 dark:bg-dark-bg">
                      <th className="px-4 py-2  text-left">Contract ID</th>
                      <th className="px-4 py-2  text-left">Contract Date</th>
                      <th className="px-4 py-2  text-left">Order ID</th>
                      <th className="px-4 py-2  text-left">Order Type</th>
                      <th className="px-4 py-2  text-left">Order Date</th>
                      <th className="px-4 py-2  text-left">Transaction ID</th>
                      <th className="px-4 py-2  text-left">Transaction Date</th>
                      <th className="px-4 py-2  text-left">Seller First Name</th>
                      <th className="px-4 py-2  text-left">Seller Last Name</th>
                      <th className="px-4 py-2  text-left">Size</th>
                      <th className="px-4 py-2  text-left">Fulfilled Price (USD)</th>
                      <th className="px-4 py-2  text-left">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className=''>
                      <td className="px-4 py-2 ">{contractId}</td>
                      <td className="px-4 py-2 ">{contractDate}</td>
                      <td className="px-4 py-2 ">{orderId}</td>
                      <td className="px-4 py-2 ">{orderType}</td>
                      <td className="px-4 py-2 ">{orderDate}</td>
                      <td className="px-4 py-2 ">{transactionId}</td>
                      <td className="px-4 py-2 ">{transactionDate}</td>
                      <td className="px-4 py-2 ">{sellerFirstName}</td>
                      <td className="px-4 py-2 ">{sellerLastName}</td>
                      <td className="px-4 py-2 ">{size}</td>
                      <td className="px-4 py-2 ">{fulfilledPrice}</td>
                      <td className="px-4 py-2 ">{status}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="w-full max-w-4xl mx-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden border border-gray-100 dark:border-gray-700">
            <div className="px-4 py-3 bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg font-bold text-gray-800 dark:text-white tracking-tight">
                Transaction Progress
              </h3>
            </div>

            <div className="relative py-6 px-4">
              {/* Horizontal Line */}
              <div className="absolute left-4 right-4 top-1/2 transform -translate-y-1/2 h-0.5 bg-gray-200 dark:bg-gray-700"></div>

              <div className="flex justify-between items-center relative">
                {transactionStages.map((stage, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center relative z-10 w-1/3"
                  >
                    {/* Stage Icon Container */}
                    <div
                      className={`
                  w-10 h-10 bg-white dark:bg-gray-800 
                  border border-gray-200 dark:border-gray-700 
                  rounded-full flex items-center justify-center 
                  mb-2 shadow-sm
                  ${index === transactionStages.length - 1
                          ? 'border-green-100 dark:border-green-900/50 bg-green-50 dark:bg-green-900/10'
                          : 'hover:bg-blue-50 dark:hover:bg-blue-900/10'}
                `}
                    >
                      {index === transactionStages.length - 1 ? (
                        <CheckCircle2
                          className="text-green-500 dark:text-green-400"
                          size={24}
                        />
                      ) : (
                        <ArrowRightCircle
                          className="text-blue-500 dark:text-blue-400"
                          size={24}
                        />
                      )}
                    </div>

                    {/* Stage Details */}
                    <div className="text-center">
                      <div className="text-sm font-medium text-gray-800 dark:text-gray-200 mb-1">
                        {stage.stage}
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">
                        {stage.date}
                      </div>
                      {stage.description && (
                        <p className="text-xs text-gray-600 dark:text-gray-300 mt-1 px-2">
                          {stage.description}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetail;


