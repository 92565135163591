import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
// import TableGDT from "../components/TableGDT"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import TooltipWrapper from "../components/TooltipWrapper";
import TopContractsBuyer from "../components/TopContractsBuyer";
import TopContractsSeller from "../components/TopContractsSeller";
export default function DataTracker() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");
  const [isBuyer, setIsBuyer] = useState();
  const [contracts, setContracts] = useState([]);

  const checkType = async () => {
    let userType = await localStorage.getItem("type");
    if (userType === "Buyer") {
      setIsBuyer(true);
      getOrdersForBuyer();
    } else {
      setIsBuyer(false);
      getOrdersForSeller();
    }
  };
  const user = useSelector((state) => state.user);
  const daysAgoFunction = async (dateString) => {
    const dateObject = await new Date(dateString);
    const currentDate = await new Date();

    const timeDifference = currentDate - dateObject;
    const daysDifference = await Math.floor(
      timeDifference / (1000 * 60 * 60 * 24)
    );
    console.log(daysDifference);

    return daysDifference;
  };

  const getOrdersForBuyer = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForBuyer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        if (response.data.data) await setContracts(response.data.data);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: false });
      });
  };

  const getOrdersForSeller = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      url: `https://web3backend.meinstein.ai/api/transactions/getOrdersForSeller`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        let allOrders = [];
        for (let i = 0; i < response.data.data.length; i++) {
          allOrders[i] = response.data.data[i];
          allOrders[i].daysAgo = await daysAgoFunction(
            response.data.data[i]?.orderDetails?.createdAt
          );
        }
        await setContracts(allOrders);
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    checkType();
  }, []);

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-dark-bg">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Hey {user}! <span className="text-slate-500 font-normal">|</span>{" "}
              Data {isBuyer ? "Buyer" : "Seller"}
            </p>
            {/* <TableGDT /> */}
            <div className="flex items-center gap-2 mt-6 ml-2 mb-8">
              <h2 className="font-bold lg:text-[34px] text-[24px] lg:text-left text-center leading-[42px] tracking-[-0.02em] text-[#2B3674] dark:text-white ml-2 ">
                Global Contracts
              </h2>
              <TooltipWrapper
                id={"globalContract"}
                data={"globalContract"}
                type={isBuyer ? "buyer" : "seller"}
              ></TooltipWrapper>
            </div>
            {isBuyer ? (
              <TopContractsBuyer
                contracts={contracts}
                blank={"globalContract"}
              />
            ) : (
              <TopContractsSeller
                contracts={contracts}
                blank={"globalContract"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
