import React from "react";
import PropTypes from "prop-types";

/**
 * @typedef {'primary' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink'} BadgeVariant
 */

/**
 * Author - Deep Chaturvedi <deep.chaturvedi@iphtechnologies.com>
 * Badge component that displays a styled badge based on the variant prop.
 * @param {Object} props
 * @param {BadgeVariant} [props.variant="primary"] - The variant of the badge
 * @param {React.ReactNode} props.children - The content inside the badge
 * @returns {JSX.Element} The rendered Badge component
 */
const Badge = ({ variant = "primary", children }) => {
  const variantClasses = {
    primary: "bg-gray-100 text-gray-600",
    red: "bg-red-100 text-red-700",
    yellow: "bg-yellow-100 text-yellow-800",
    green: "bg-green-100 text-green-700",
    blue: "bg-blue-100 text-blue-700",
    indigo: "bg-indigo-100 text-indigo-700",
    purple: "bg-purple-100 text-purple-700",
    pink: "bg-pink-100 text-pink-700",
  };

  const badgeClass = variantClasses[variant] || variantClasses.primary;

  return (
    <span
      className={`inline-flex items-center capitalize rounded-md px-2 py-1 text-xs font-semibold  ${badgeClass}`}
    >
      {children}
    </span>
  );
};

// Prop validation for runtime validation
Badge.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "red",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
  ]),
  children: PropTypes.node.isRequired,
};

export default Badge;
