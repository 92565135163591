import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { countries } from "../data/countries";
// import { contractBuyer } from "../data/tooltipData";
import TooltipWrapper from "./TooltipWrapper";

export default function CreateContract() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let auth = localStorage.getItem("auth");

  const [values, setValues] = React.useState({
    effectiveDate: "",
    companyName: "",
    address: "",
    country: "",
    contractDescription: "",
    DRDuration: "",
    DRPeriodType: "",
    DDDuration: "",
    DDPeriodType: "",
    CTDuration: "",
    CTPeriod: "",
    DeliveryDDuration: "",
    DeliveryDPeriodType: "",
    DPDuration: "",
    DPPeriod: "",
    ageRange: "",
    gender: "",
    minimum: "",
    maximum: "",
  });
  const [showModal, setShowModal] = React.useState(false);
  const [errorMsg, setError] = React.useState("");
  const [accordionState, setAccordionState] = React.useState({});
  const [selectedFilters, setSelectedFilters] = React.useState({});
  const [dataTypes, setDataTypes] = React.useState({});
  const [filterData, setFilterData] = React.useState({});
  const [searchQuery, setSearchQuery] = React.useState("");
  const accordionRef = useRef(null);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggleAccordion = async (key) => {
    let allMainKeys = await Object.keys(dataTypes);
    if (allMainKeys.includes(key)) {
      await setAccordionState((prevState) => ({
        [key]: !prevState[key],
      }));
    } else {
      await setAccordionState((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    }
  };

  const getDataTypes = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      url: `https://web3backend.meinstein.ai/api/contract-fields`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data.data) {
          await setDataTypes(response.data.data);
          await setFilterData(response.data.data);
        }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getDataTypes();
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setAccordionState({});
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleFilter = (category, subCategory, filter, e) => {
    e.stopPropagation();
    const isChecked = e.target.checked;
    setSelectedFilters((prevState) => {
      const updatedState = { ...prevState };
      if (subCategory) {
        if (!updatedState[category]) updatedState[category] = {};
        if (!updatedState[category][subCategory])
          updatedState[category][subCategory] = [];
        if (isChecked) {
          let isIncluded = updatedState[category][subCategory].includes(filter);
          if (!isIncluded) {
            updatedState[category][subCategory] = [
              ...updatedState[category][subCategory],
              filter,
            ];
          }
        } else {
          updatedState[category][subCategory] = updatedState[category][
            subCategory
          ].filter((item) => item !== filter);
        }
      } else {
        if (!updatedState[category]) updatedState[category] = [];
        if (updatedState[category].includes(filter)) {
          updatedState[category] = updatedState[category].filter(
            (item) => item !== filter
          );
        } else {
          updatedState[category] = [...updatedState[category], filter];
        }
      }
      return updatedState;
    });
  };

  const createContract = async (e) => {
    e.preventDefault();
    if (
      selectedFilters &&
      values.minimum &&
      values.maximum &&
      values.effectiveDate &&
      values.companyName &&
      values.address &&
      values.country &&
      values.contractDescription &&
      values.DRDuration &&
      values.DRPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.CTDuration &&
      values.CTPeriod &&
      values.DeliveryDDuration &&
      values.DeliveryDPeriodType &&
      values.DPDuration &&
      values.DPPeriod
    ) {
      await dispatch({ type: "loading", payload: true });
      await axios({
        method: "post",
        url: `https://web3backend.meinstein.ai/api/requested_data/create`,
        data: {
          category: Object.keys(selectedFilters),
          category_filter: {
            price: 1,
            status: "pending",
            selectedFilters: selectedFilters,
            deliveryDuration: values.DeliveryDDuration,
            deliveryPeriod: values.DeliveryDPeriodType,
            disputeDuration: values.DPDuration,
            disputePeriod: values.DPPeriod,
          },
          minPrice: values.minimum,
          maxPrice: values.maximum,
          effectiveDate: values.effectiveDate,
          buyerCompanyName: values.companyName,
          buyerAddress: values.address,
          buyerCountry: values.country,
          contractDescription: values.contractDescription,
          dataRetentionDuration: values.DRDuration,
          dataRetentionPeriod: values.DRPeriodType,
          dataDeletionDuration: values.DDDuration,
          dataDeletionPeriod: values.DDPeriodType,
          contractTerminationDuration: values.CTDuration,
          contractTerminationPeriod: values.CTPeriod,
          age: values.ageRange,
          gender: values.gender,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: auth,
        },
      })
        .then(async (response) => {
          if (response.data.success) await navigate("/dataPackages");
          await dispatch({ type: "loading", payload: false });
        })
        .catch(async (error) => {
          await dispatch({ type: "loading", payload: true });
        });
    } else setError("Please fill all the fields");
  };

  const reviewContract = async (e) => {
    e.preventDefault();
    if (
      selectedFilters &&
      values.minimum &&
      values.maximum &&
      values.effectiveDate &&
      values.companyName &&
      values.address &&
      values.country &&
      values.contractDescription &&
      values.DRDuration &&
      values.DRPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.DDDuration &&
      values.DDPeriodType &&
      values.CTDuration &&
      values.CTPeriod &&
      values.DeliveryDDuration &&
      values.DeliveryDPeriodType &&
      values.DPDuration &&
      values.DPPeriod
    ) {
      setShowModal(true);
    } else setError("Please fill all the fields");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  function filteringData(data, keyword) {
    const lowerCasekeyword = keyword.toLowerCase();

    function matches(value) {
      if (typeof value === "string") {
        return value.toLowerCase().includes(lowerCasekeyword);
      }
      if (Array.isArray(value)) {
        return value.some((item) => matches(item));
      }
      if (typeof value === "object" && value !== null) {
        return filteringData(value, keyword);
      }
      return false;
    }

    if (Array.isArray(data)) {
      return data.filter((item) => matches(item));
    } else if (typeof data === "object" && data !== null) {
      const result = {};
      Object.keys(data).forEach((key) => {
        const value = data[key];
        const filteredValue = filteringData(value, keyword);
        if (
          filteredValue &&
          (Array.isArray(filteredValue)
            ? filteredValue.length
            : Object.keys(filteredValue).length > 0)
        ) {
          result[key] = filteredValue;
        }
      });
      return Object.keys(result).length > 0 ? result : null;
    }
    return false;
  }

  useEffect(() => {
    if (searchQuery) {
      const filteredData = filteringData(dataTypes, searchQuery);
      setFilterData(filteredData || {});
    } else {
      setFilterData(dataTypes);
    }
  }, [searchQuery, dataTypes]);

  const RenderCategories = () => {
    return (
      <div className="flex">
        {Object.keys(filterData).map((parentCategory) => (
          <div key={parentCategory}>
            <div
              className={`inline-block ${
                accordionState[parentCategory]
                  ? "border-2 border-[#11047A] py-1 font-semibold"
                  : "bg-dark-bg text-white py-1"
              } rounded-lg font-medium leading-6 text-xs px-3 mb-3 mr-2`}
            >
              <h2
                className="inline-block"
                onClick={() => toggleAccordion(parentCategory)}
              >
                {parentCategory}
              </h2>
            </div>
            {accordionState[parentCategory] && (
              <div className="mb-2 rounded-[16px] absolute z-10 bg-slate-700 md:w-[250px] max-h-[250px] overflow-y-auto">
                {!Array.isArray(dataTypes[parentCategory]) ? (
                  <div className="px-2 py-4">
                    {Object.keys(dataTypes[parentCategory]).map((category) => (
                      <div
                        key={`${parentCategory}-${category}`}
                        className="rounded-lg border-2 text-white bg-slate-500 border-dark-bg mb-1 px-4 py-1"
                      >
                        <h3
                          className="text-sm w-full"
                          onClick={() => toggleAccordion(category)}
                        >
                          {category}
                        </h3>
                        <div className="rounded-[16px] z-10 bg-slate-700">
                          {accordionState[category] && (
                            <div className="px-2 py-2 mb-2">
                              {Object.keys(
                                dataTypes[parentCategory][category]
                              ).map((subCategory) => (
                                <div
                                  key={subCategory}
                                  className="rounded-lg border-2 text-white bg-slate-500 border-dark-bg mb-1 px-4 py-1"
                                >
                                  <h4
                                    className="text-sm text-gray-200"
                                    onClick={() => toggleAccordion(subCategory)}
                                  >
                                    {subCategory}
                                  </h4>
                                  {accordionState[subCategory] && (
                                    <ul>
                                      {dataTypes[parentCategory][category][
                                        subCategory
                                      ].map((item) => (
                                        <li key={item}>
                                          <div className="flex items-center md:p-2">
                                            <input
                                              id={`checkbox-item-${item}`}
                                              type="checkbox"
                                              checked={selectedFilters[
                                                category
                                              ]?.[subCategory]?.includes(item)}
                                              onChange={(e) => {
                                                toggleFilter(
                                                  category,
                                                  subCategory,
                                                  item,
                                                  e
                                                );
                                              }}
                                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                            />
                                            <label
                                              htmlFor={`checkbox-item-${item}`}
                                              className="w-full text-xs text-gray-200 ms-2"
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  filterData[parentCategory].map((item) => (
                    <div key={item}>
                      <div className="flex items-center md:p-2 ml-2">
                        <input
                          id={`checkbox-item-${item}`}
                          type="checkbox"
                          checked={selectedFilters[parentCategory]?.includes(
                            item
                          )}
                          onClick={(e) => {
                            toggleFilter(parentCategory, "", item, e);
                          }}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor={`checkbox-item-${item}`}
                          className="w-full text-xs text-gray-200 ms-2"
                        >
                          {item}
                        </label>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="mt-[31px] mx-5">
        <p className="font-Poppins lg:text-xl text-base font-bold text-[#2B3674] dark:text-white text-end mr-5">
          Hey {user}! <span className="text-slate-500 font-normal">|</span> Data
          Buyer
        </p>
        <div className="flex items-center gap-2 mt-6 ml-2">
          <h2 className="font-bold lg:text-[34px] max-[375px]:text-[20px] text-[24px] lg:text-left text-center tracking-[-0.02em] text-[#2B3674] dark:text-white   ">
            Create New Data Contract
          </h2>
          <TooltipWrapper
            id={"contractBuyer"}
            data={"contractBuyer"}
            type={"buyer"}
          ></TooltipWrapper>
        </div>
        <div className="bg-white dark:bg-dark-container shadow-[14px_17px_40px_4px_rgba(112,144,176,0.08)] mt-5 mb-20 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] min-h-[540px]">
          <div>
            <div className="text-slate-800 dark:text-white text-sm sm:text-base sm:leading-[26px] mt-5">
              <p>
                To create a data contract on our platform, follow these steps:
              </p>
              <p className="pl-2">
                1. Specify the type of data you want to purchase, the total
                amount in KB you want to pay per user, the minimum and maximum
                amount of KB per user.
              </p>
              <p className="pl-2">
                2. Select the user demographics you are interested in, such as
                age, gender, location, and interests.
              </p>
              <p className="pl-2">
                3. Place the total amount of tokens in escrow to cover the cost
                of the data and the DAO's fee.
              </p>
              <p>
                Once you have completed these steps, your data contract will be
                created and users will be able to accept it and sell their data
                to you. This process allows you to purchase high-quality data
                from users on our platform and helps ensure a fair and
                transparent market for data.
              </p>
            </div>
            <hr className="mt-2" />
            <div className="tracking-[-0.02em] text-[#2B3674] dark:text-white mt-14">
              <div className="text-[12.5px] font-normal flex flex-wrap gap-y-3 gap-x-6">
                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Effective Date*</label>
                    <input
                      type="date"
                      name="effectiveDate"
                      onChange={handleChange}
                      value={values.effectiveDate}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Company Name*</label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={values.companyName}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Company Name"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Address*</label>
                    <input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Address"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Country*</label>
                    <select
                      name="country"
                      onChange={handleChange}
                      value={values.countryCode}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select your country
                      </option>
                      {countries.length &&
                        countries.map((data, index) => (
                          <option key={index} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <label className="ml-2">Contract Description*</label>
                    <textarea
                      rows="5"
                      col="20"
                      name="contractDescription"
                      onChange={handleChange}
                      value={values.contractDescription}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Description"
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Retention Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataRetaintionWindow"}
                          data={"dataRetaintionWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DRDuration"
                      onChange={handleChange}
                      value={values.DRDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Data Retention Duration"
                      max={1825}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Retention Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataRetentionTimeframe"}
                          data={"dataRetentionTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DRPeriodType"
                      onChange={handleChange}
                      value={values.DRPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      {values.DRDuration <= 260 && (
                        <option value="Weeks">Weeks</option>
                      )}
                      {values.DRDuration <= 60 && (
                        <option value="Months">Months</option>
                      )}
                      {values.DRDuration <= 5 && (
                        <option value="Years">Years</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Deletion Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataDeletionWindow"}
                          data={"dataDeletionWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DDDuration"
                      onChange={handleChange}
                      value={values.DDDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Data Deletion Duration "
                      max={60}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Data Deletion Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"dataDeletionTimeframe"}
                          data={"dataDeletionTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DDPeriodType"
                      onChange={handleChange}
                      value={values.DDPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      {values.DDDuration <= 8 && (
                        <option value="Weeks">Weeks</option>
                      )}
                      {values.DDDuration <= 2 && (
                        <option value="Months">Months</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Contract Termination Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"contractTerminationWindow"}
                          data={"contractTerminationWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="CTDuration"
                      onChange={handleChange}
                      value={values.CTDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Contract Termination Duration"
                      max={14}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Contract Termination Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"contractTerminationTimeframe"}
                          data={"contractTerminationTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="CTPeriod"
                      onChange={handleChange}
                      value={values.CTPeriod}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      {values.CTDuration <= 2 && (
                        <option value="Weeks">Weeks</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Delivery Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"deliveryWindow"}
                          data={"deliveryWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <input
                      type="number"
                      name="DeliveryDDuration"
                      onChange={handleChange}
                      value={values.DeliveryDDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Delivery Duration"
                      max={14}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Delivery Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"deliveryTimeframe"}
                          data={"deliveryTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>
                    <select
                      name="DeliveryDPeriodType"
                      onChange={handleChange}
                      value={values.DeliveryDPeriodType}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      {values.DeliveryDDuration <= 2 && (
                        <option value="Weeks">Weeks</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Dispute Window
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"disputeWindow"}
                          data={"disputeWindow"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>

                    <input
                      type="number"
                      name="DPDuration"
                      onChange={handleChange}
                      value={values.DPDuration}
                      className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Enter Dispute Duration"
                      max={14}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:w-[23%] w-full">
                  <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                    <div className="flex items-center gap-1 ">
                      <label title="" className="ml-2">
                        Dispute Timeframe
                      </label>
                      <span className="text-xs">
                        <TooltipWrapper
                          id={"disputeTimeframe"}
                          data={"disputeTimeframe"}
                          type={"createContract"}
                          fontSize={"text-base"}
                        ></TooltipWrapper>
                      </span>
                    </div>

                    <select
                      name="DPPeriod"
                      onChange={handleChange}
                      value={values.DPPeriod}
                      className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    >
                      <option value="" selected disabled>
                        Select Period
                      </option>
                      <option value="Days">Days</option>
                      {values.DPDuration <= 2 && (
                        <option value="Weeks">Weeks</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <p className="my-5 ml-2 text-sm font-medium">Data Types</p>
                <div className="mb-4 w-full">
                  <input
                    type="text"
                    placeholder="Search categories..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  />
                </div>
                <div className="relative flex flex-wrap cursor-pointer">
                  <RenderCategories />
                </div>
              </div>

              <div>
                <p className="my-5 ml-2 text-sm font-medium">
                  User Demographics
                </p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Age Range*</label>
                      <select
                        name="ageRange"
                        onChange={handleChange}
                        value={values.ageRange}
                        className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Age Range
                        </option>
                        <option value="Teenagers">
                          Teenagers (13-17 years)
                        </option>
                        <option value="Young Adults">
                          Young Adults (18-24 years)
                        </option>
                        <option value="Adults">Adults (25-34 years)</option>
                        <option value="Young Professionals">
                          Young Professionals (25-44 years)
                        </option>
                        <option value="Middle-Aged Adults">
                          Middle-Aged Adults (35-54 years)
                        </option>
                        <option value="Seniors">Seniors (55-64 years)</option>
                        <option value="Elderly">Elderly (65 and above)</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Gender*</label>
                      <select
                        name="gender"
                        onChange={handleChange}
                        value={values.gender}
                        className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="" selected disabled>
                          Select Gender
                        </option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="my-5 ml-2 text-sm font-medium">Price Range</p>

                <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap gap-y-3 gap-x-6 ml-2">
                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Minimum*</label>
                      <input
                        type="number"
                        name="minimum"
                        onChange={handleChange}
                        value={values.minimum}
                        className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Minimum"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col lg:w-[23%] w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                      <label className="ml-2">Maximum*</label>
                      <input
                        type="number"
                        name="maximum"
                        onChange={handleChange}
                        value={values.maximum}
                        className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Enter Maximum"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errorMsg ? (
                <p className="text-lg font-semibold text-center text-red-700 bg-red-50">
                  {errorMsg}
                </p>
              ) : null}

              <div className="flex justify-end">
                <button
                  className="min-w-max text-white bg-theme-purple rounded-[47px] font-medium text-sm leading-6 my-3 px-7 py-1"
                  onClick={reviewContract}
                >
                  Create Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-sm mx-auto my-6">
              <div className="border-0 lg:w-[800px] w-[350px] max-h-[90vh] rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between px-5 py-2 border-b border-solid rounded-t border-slate-200">
                  <h3 className="mb-0 text-xl font-semibold">Review Data</h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative flex-auto p-6 overflow-y-auto">
                  <div className="tracking-[-0.02em] text-[#2B3674] dark:text-white">
                    <div className="text-[12.5px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4">
                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Effective Date*</label>
                          <input
                            type="date"
                            name="effectiveDate"
                            onChange={handleChange}
                            value={values.effectiveDate}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Company Name*</label>
                          <input
                            type="text"
                            name="companyName"
                            onChange={handleChange}
                            value={values.companyName}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Company Name"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Address*</label>
                          <input
                            type="text"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Address"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Country*</label>
                          <input
                            type="text"
                            name="country"
                            onChange={handleChange}
                            value={values.country}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Country"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Contract Description*</label>
                          <textarea
                            rows="5"
                            col="20"
                            name="contractDescription"
                            onChange={handleChange}
                            value={values.contractDescription}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Contract Description"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">
                            Data Retention Duration*
                          </label>
                          <input
                            type="number"
                            name="DRDuration"
                            onChange={handleChange}
                            value={values.DRDuration}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Data Retention Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Retention Period*</label>
                          <select
                            name="DRPeriodType"
                            onChange={handleChange}
                            value={values.DRPeriodType}
                            className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">
                            Data Deletion Duration*
                          </label>
                          <input
                            type="number"
                            name="DDDuration"
                            onChange={handleChange}
                            value={values.DDDuration}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Data Deletion Duration "
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">Data Deletion Period*</label>
                          <select
                            name="DDPeriodType"
                            onChange={handleChange}
                            value={values.DDPeriodType}
                            className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">
                            Contract Termination Duration*
                          </label>
                          <input
                            type="number"
                            name="CTDuration"
                            onChange={handleChange}
                            value={values.CTDuration}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Contract Termination Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label className="ml-2">
                            Contract Termination Period*
                          </label>
                          <select
                            name="CTPeriod"
                            onChange={handleChange}
                            value={values.CTPeriod}
                            className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label
                            title="The Seller commits to delivering the Data to the Buyer within this duration."
                            className="ml-2"
                          >
                            Delivery Duration*
                          </label>
                          <input
                            type="number"
                            name="DeliveryDDuration"
                            onChange={handleChange}
                            value={values.DeliveryDDuration}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Delivery Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label
                            title="The Seller commits to delivering the Data to the Buyer within this duration."
                            className="ml-2"
                          >
                            Delivery Period*
                          </label>
                          <select
                            name="DeliveryDPeriodType"
                            onChange={handleChange}
                            value={values.DeliveryDPeriodType}
                            className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label
                            title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'."
                            className="ml-2"
                          >
                            Dispute Duration*
                          </label>
                          <input
                            type="number"
                            name="DPDuration"
                            onChange={handleChange}
                            value={values.DPDuration}
                            className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Enter Dispute Duration"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col lg:w-[23%] w-full">
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                          <label
                            title="In the event the Buyer identifies that the delivered Data is not in compliance with the agreed standards of quality, accuracy, or timeliness, the Buyer shall notify the Seller within this duration of receipt of the Data as a 'Dispute'."
                            className="ml-2"
                          >
                            Dispute Period*
                          </label>
                          <select
                            name="DPPeriod"
                            onChange={handleChange}
                            value={values.DPPeriod}
                            className="block w-full px-3 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            <option value="" selected disabled>
                              Select Period
                            </option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="my-5 ml-2 text-sm font-medium">
                        Data Types
                      </p>
                      <div className="mb-4 w-full">
                        <input
                          type="text"
                          placeholder="Search categories..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="block w-full px-6 py-2 m-0 mt-2 text-gray-700 transition ease-in-out bg-white border border-gray-300 border-solid form-control rounded-xl dark:text-gray-200 dark:bg-dark-bg bg-clip-padding dark:border-none focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        />
                      </div>
                      <div className="relative flex flex-wrap cursor-pointer">
                        <RenderCategories />
                      </div>
                    </div>

                    <div>
                      <p className="my-5 ml-2 text-sm font-medium">
                        User Demographics
                      </p>

                      <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4 ml-2">
                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Age Range*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">
                              {values.ageRange}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Gender*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">
                              {values.gender}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="my-5 ml-2 text-sm font-medium">
                        Price Range
                      </p>

                      <div className="text-[8.13px] text-[#A3AED0] leading-[13.93px] font-normal flex flex-wrap lg:gap-y-3 lg:gap-x-6 gap-y-1 gap-x-4 ml-2">
                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Minimum*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">
                              {values.minimum}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col lg:w-[23%] w-full">
                          <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#2B3674] dark:text-white">
                            <label className="ml-2">Maximum*</label>
                            <p className="ml-2 font-semibold text-[18px] mt-3">
                              {values.maximum}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {errorMsg ? (
                      <p className="text-sm font-semibold text-center text-red-700">
                        {errorMsg}
                      </p>
                    ) : null}

                    <div className="flex justify-end">
                      <button
                        className="min-w-max text-white bg-[#11047A] rounded-[47px] font-medium text-sm leading-6 my-3 px-7 py-1"
                        onClick={createContract}
                      >
                        Create Contract
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
