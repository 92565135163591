import React, { useEffect, useState } from "react";
import { FaChevronLeft as ChevronLeft } from "react-icons/fa";
import { MdDownload as Download } from "react-icons/md";
import { TbAlertTriangle as AlertTriangle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../components/SideBar";
import axios from "axios";
import { getFormattedDate } from "../utils/common";
import Badge from "../components/Badge";

export default function DownloadData() {
  const dispatch = useDispatch();
  let auth = localStorage.getItem("auth");

  const { OrdersById } = useParams();
  // const [isSelectMode, setIsSelectMode] = useState(false);

  const [orderInfo, setOrderInfo] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  // State for showing the modal
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const label_HEAD = [
    { title: "Contract ID", path: "orderId" },
    { title: "Contract Date", path: "created_at", isDate: true },
    {
      title: "Contract Expiry Date",
      path: "order_details.contractTerminationDuration|order_details.contractTerminationPeriod",
    },
    {
      title: "Data Retention Period",
      path: "order_details.dataRetentionDuration|order_details.dataRetentionPeriod",
    },
    {
      title: "Data Deletion Period",
      path: "order_details.dataDeletionDuration|order_details.dataDeletionPeriod",
    },
    {
      title: "Delivery Period",
      path: "order_details.category_filter.deliveryDuration|order_details.category_filter.deliveryPeriod",
    },
    {
      title: "Dispute Period",
      path: "order_details.category_filter.disputeDuration|order_details.category_filter.disputePeriod",
    },
    { title: "Order Type", path: "" },
    { title: "Order Date", path: "order_details.effectiveDate", isDate: true },
    { title: "Size (KB)", path: "" },
    { title: "Request Quantity", path: "qty" },
    { title: "Fulfilled Quantity", path: "fulfilledQty" },
    { title: "Disputed Quantity", path: "disputedQty" },
    { title: "Price Min", path: "order_details.minPrice" },
    { title: "Price Max", path: "order_details.maxPrice" },
  ];

  const TABLE_HEAD = [
    "Transaction ID",
    "Transaction Date",
    "Seller First Name",
    "Seller Last Name",
    "Size",
    "Fulfilled Price (USD)",
    "Status",
  ];

  const isDisputeValid = (validUntil) => {
    const currentDate = new Date();
    const validDate = new Date(validUntil);
    return currentDate <= validDate;
  };
  const handleDetailsClick = (row) => {
    setShowModal(true);
    // Set selected transaction details for the modal
    setSelectedTransaction(row);
  };

  const handleRowSelect = (row, isCheckbox = false) => {
    setSelectedRows((prev) => {
      const isSelected = prev.some((r) => r.id === row.id);
      if (isSelected) {
        return prev.filter((r) => r.id !== row.id);
      } else {
        return [...prev, row];
      }
    });
  };
  const handleRowClick = (row) => {
    handleRowSelect(row);
    navigate(`/transactions/${row?.id}`);
  };
  const handleSelectAll = (e) => {
    e.stopPropagation();
    setSelectedRows((prev) =>
      prev.length === orderInfo?.data_hashes?.length
        ? []
        : orderInfo?.data_hashes
    );
  };

  const handleDownload = () => {
    const allCompleted = selectedRows.every((row) => {
      const latestStatus = row["Status History"]
        ? Array.isArray(row["Status History"])
          ? row["Status History"].length > 0
            ? row["Status History"][row["Status History"].length - 1].status
            : undefined
          : row["Status History"]?.status
        : "No Status";

      return (
        latestStatus === "Resolved" || latestStatus === "Transaction Completed"
      );
    });

    if (!allCompleted) {
      setShowModalDownload(true);
    } else {
      alert("Downloading selected rows:", selectedRows);
    }
  };

  const fetchOrderData = async () => {
    dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      url: `https://web3backend.meinstein.ai/api/payment/info/${OrdersById}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        console.log(response.data.data);
        setOrderInfo(response.data.data);
        dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        console.log(error.response);
        dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <>
      {showModalDownload && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full dark:bg-dark-container w- dark:text-white max-[425px]:w-11/12">
            <div className="flex items-center justify-center mb-4 text-yellow-500">
              <AlertTriangle size={48} />
            </div>
            <h2 className="text-xl font-bold text-center mb-4">
              Cannot Download
            </h2>
            <p className="text-center mb-4">
              Some selected transactions are not yet completed. Please wait
              until all transactions are completed.
            </p>
            <button
              onClick={() => setShowModalDownload(false)}
              className="w-full bg-blue-500 text-white py-2 rounded-lg dark:bg-dark-bg"
            >
              Okay
            </button>
          </div>
        </div>
      )}
      {showModal && selectedTransaction && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-xl w-full dark:bg-dark-container dark:text-white">
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-900 dark:text-gray-100">
              Transaction Details
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-6">
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Transaction ID:
                </strong>
                <p className="text-sm mt-2">{selectedTransaction?.id}</p>
              </div>
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Transaction Date:
                </strong>
                <p className="text-sm mt-2">
                  {getFormattedDate(selectedTransaction?.created_at)}
                </p>
              </div>
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Seller Name:
                </strong>
                <p className="text-sm mt-2">
                  {selectedTransaction?.userInfo?.first_name}{" "}
                  {selectedTransaction?.userInfo?.last_name}
                </p>
              </div>
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Size:
                </strong>
                <p className="text-sm mt-2">{selectedTransaction?.size}</p>
              </div>
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Fulfilled Price:
                </strong>
                <p className="text-sm mt-2">${selectedTransaction?.price}</p>
              </div>
              <div className="p-4 border rounded-lg bg-gray-50 dark:bg-dark-box dark:text-white">
                <strong className="text-lg text-gray-900 dark:text-gray-200">
                  Dispute Valid Until:
                </strong>
                <p className="text-sm mt-2">
                  {selectedTransaction?.validUntil || "NA"}
                </p>
              </div>
            </div>

            <h3 className="font-semibold text-2xl mt-6 mb-4 text-center">
              Status History
            </h3>
            <div className="relative">
              <div className="absolute inset-0 w-full h-1 bg-gray-300 dark:bg-gray-600 top-1/2"></div>

              <div className="flex justify-between items-center space-x-4">
                {selectedTransaction?.statusHistory?.length &&
                  selectedTransaction?.statusHistory?.map((item, index) => {
                    const isCurrentStep =
                      index === selectedTransaction?.statusHistory.length - 1;
                    const isCompleted =
                      item.status === "Resolved" ||
                      item.status === "Transaction Completed";

                    return (
                      <div
                        key={index}
                        className="relative flex flex-col items-center"
                      >
                        <div
                          className={`w-8 h-8 flex items-center justify-center rounded-full border-4 
                    ${
                      isCompleted
                        ? "bg-theme-purple border-theme-purple"
                        : isCurrentStep
                        ? "bg-green-500 border-green-500"
                        : "bg-gray-300 border-gray-500"
                    }`}
                        >
                          <div
                            className={`w-4 h-4 rounded-full ${
                              isCompleted || isCurrentStep
                                ? "bg-white"
                                : "bg-gray-500"
                            }`}
                          ></div>
                        </div>

                        <div className="text-center mt-2">
                          <p
                            className={`text-sm ${
                              isCompleted
                                ? "text-theme-purple"
                                : isCurrentStep
                                ? "text-green-500"
                                : "text-gray-500"
                            }`}
                          >
                            {item.status}
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            {getFormattedDate(item?.statusHistory?.createdAt)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="flex justify-center mt-6">
              <button
                onClick={() => setShowModal(false)}
                className="w-full sm:w-auto mt-4 bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-500 transition duration-300 ease-in-out dark:bg-dark-bg dark:hover:bg-blue-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />

        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-dark-bg">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Hey {user}! <span className="text-slate-500 font-normal">|</span>{" "}
              Data Buyer
            </p>
            <div className="flex items-center gap-2 mt-6 ml-2">
              <h2 className="font-bold lg:text-[34px] max-[375px]:text-[20px] text-[24px] lg:text-left text-center tracking-[-0.02em] text-[#2B3674] dark:text-white">
                Order Details
              </h2>
            </div>

            <div className="bg-white dark:bg-dark-container mt-4 rounded-[20px] p-6">
              <div className="flex flex-col gap-4">
                <button
                  className="flex items-center gap-2 bg-slate-100 dark:bg-dark-bg p-2 pr-4 rounded-lg self-start"
                  onClick={() => window.history.back()}
                >
                  <ChevronLeft size={20} />
                  <span className="hidden sm:inline">Back</span>
                </button>

                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                  {label_HEAD.map((head) => (
                    <div
                      key={head}
                      className="bg-blue-50 dark:bg-slate-700 p-3 rounded-lg flex flex-col"
                    >
                      <span className="text-xs font-semibold text-gray-600 dark:text-gray-300 mb-1">
                        {head.title}
                      </span>
                      <span className="text-sm font-bold text-[#2B3674] dark:text-white">
                        {head.path
                          ? head.path.includes("|")
                            ? head.path
                                .split("|")
                                .map((p) => p.trim())
                                .map(
                                  (p) =>
                                    p
                                      .split(".")
                                      .reduce(
                                        (obj, key) =>
                                          obj &&
                                          (head?.isDate
                                            ? getFormattedDate(obj[key])
                                            : obj[key]),
                                        orderInfo
                                      ) || "N/A"
                                )
                                .join(" ")
                            : head.path
                                .split(".")
                                .reduce(
                                  (obj, key) =>
                                    obj &&
                                    (head?.isDate
                                      ? getFormattedDate(obj[key])
                                      : obj[key]),
                                  orderInfo
                                ) || "N/A"
                          : "N/A"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-dark-container my-5 rounded-[20px] px-[30px] py-5 tracking-[-0.02em] mb-12">
              <div className="flex items-center flex-wrap gap-4 mb-4">
                <div className="flex items-center gap-4">
                  {selectedRows.length > 0 && (
                    <button
                      onClick={handleDownload}
                      className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-full"
                    >
                      <Download size={16} />
                      Download ({selectedRows.length})
                    </button>
                  )}
                </div>
              </div>

              <div className="overflow-x-auto">
                <table className="w-full min-w-[1200px] md:min-w-full table-auto text-left border-collapse">
                  <thead>
                    <tr>
                      {
                        <th className="p-4 text-left sticky top-0 z-10 w-[50px]">
                          <input
                            type="checkbox"
                            checked={
                              selectedRows.length ===
                              orderInfo?.data_hashes?.length
                            }
                            onChange={handleSelectAll}
                            className="form-checkbox"
                          />
                        </th>
                      }
                      {TABLE_HEAD.map((head) => (
                        <th
                          key={head}
                          className="bg-blue-gray-50 p-4 text-left align-top sticky top-0 z-10"
                        >
                          <span className="text-blue-gray-900 font-bold text-sm opacity-70">
                            {head}
                          </span>
                        </th>
                      ))}
                      <th className="sticky top-0 z-10 p-4">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="border-t-2">
                    {orderInfo?.data_hashes?.length > 0
                      ? orderInfo?.data_hashes?.map((transactionData) => {
                          // const { status, date } = getLatestStatus(
                          //   row["Status History"]
                          // );
                          const disputeValid =
                            isDisputeValid(
                              transactionData?.DisputeValidUntil
                            ) || "NA";

                          return (
                            <tr
                              key={transactionData.id}
                              onClick={() => handleRowClick(transactionData)}
                              className={`cursor-pointer ${
                                selectedRows.some(
                                  (r) => r.id === transactionData?.id
                                )
                                  ? "bg-blue-50 dark:bg-slate-700"
                                  : "hover:bg-gray-100 dark:hover:bg-slate-600"
                              }`}
                            >
                              {
                                <td
                                  className="p-4"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.some(
                                      (r) => r.id === transactionData?.id
                                    )}
                                    onChange={() =>
                                      handleRowSelect(transactionData, true)
                                    }
                                    className="form-checkbox"
                                  />
                                </td>
                              }
                              <td className="p-4 text-sm">
                                {transactionData["id"] || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                {transactionData["created_at"] || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                {transactionData?.userInfo?.first_name || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                {transactionData?.userInfo?.last_name || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                {transactionData?.size || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                {transactionData[""] || "NA"}
                              </td>
                              <td className="p-4 text-sm">
                                <Badge
                                  variant={
                                    transactionData["status"] === "fulfilled"
                                      ? "green"
                                      : transactionData["status"] ===
                                          "dispute" && "red"
                                  }
                                >
                                  {transactionData["status"]}
                                </Badge>
                              </td>

                              <td className="p-4 text-sm flex">
                                {
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (disputeValid)
                                        alert("Dispute created");
                                    }}
                                    disabled={
                                      !disputeValid ||
                                      transactionData?.status === "dispute"
                                    }
                                    className={`flex items-center justify-center h-[30px] w-[93px] rounded-full ${
                                      disputeValid &&
                                      transactionData?.status !== "dispute"
                                        ? "bg-[#11047A] text-white cursor-pointer"
                                        : "bg-gray-300 text-gray-600 cursor-not-allowed"
                                    }`}
                                  >
                                    Dispute
                                  </button>
                                }
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDetailsClick(transactionData);
                                  }}
                                  className="ms-2 flex bg-blue-500 text-white font-medium h-[30px] items-center justify-center cursor-pointer w-[93px] rounded-full"
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
