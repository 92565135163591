import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { BuyDataPackage } from "../contractIntreaction";
export default function TopContracts(props) {
  const inputRef = useRef(null);

  const userId = localStorage.getItem("login");
  const { address, isConnected } = useAccount();
  const [isOpenAge, setIsOpenAge] = useState(false);
  const [isOpenGender, setIsOpenGender] = useState(false);

  const dropdownAgeRef = useRef(null);
  const dropdownGenderRef = useRef(null);
  const [filters, setFilters] = useState({
    country: "",
    zip_code: "",
    state: "",
    age: [],
    gender: [],
  });
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const ImageComponent = () => {
    const imageSrc = require(`../img/BlankScreenIcons/${props.blank}.svg`);
    return <img src={imageSrc} alt={props.blank} />;
  };

  const applyFilters = () => {
    const filteredContracts = props.contracts.filter((contract) => {
      const country =
        !filters.country ||
        (contract?.data_detail?.country &&
          new RegExp(filters.country, "i").test(contract.data_detail.country));

      const zip_code =
        !filters.zip_code ||
        (contract?.data_detail?.zipcode &&
          new RegExp(filters.zip_code, "i").test(contract.data_detail.zipcode));

      const state =
        !filters.state ||
        (contract?.data_detail?.state &&
          new RegExp(filters.state, "i").test(contract.data_detail.state));

      const agePass =
        filters.age.length === 0 ||
        (contract?.data_detail?.age &&
          filters.age.some((ageRange) => {
            const [minAge, maxAge] = ageRange.split("-").map(Number);
            const contractAge = Number(contract.data_detail.age);
            return contractAge >= minAge && contractAge <= maxAge;
          }));

      const genderPass =
        filters.gender.length === 0 ||
        (contract?.data_detail?.gender &&
          filters.gender.includes(contract?.data_detail?.gender));

      return agePass && genderPass && country && zip_code && state;
    });

    return filteredContracts;
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (checked) {
        setFilters({
          ...filters,
          [name]: [...filters[name], value],
        });
      } else {
        setFilters({
          ...filters,
          [name]: filters[name].filter((data) => data !== value),
        });
      }
    } else {
      // For other inputs like minPrice and maxPrice, handle as before
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownAgeRef.current &&
        !dropdownAgeRef.current.contains(event.target)
      ) {
        setIsOpenAge(false);
      }
      if (
        dropdownGenderRef.current &&
        !dropdownGenderRef.current.contains(event.target)
      ) {
        setIsOpenGender(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAgeLabel = (age) => {
    return age === "65" ? "65 and above" : `${age} years`;
  };

  const removeAgeChip = (ageToRemove) => {
    const newAges = filters.age.filter((age) => age !== ageToRemove);
    handleFilterChange({
      target: {
        name: "age",
        value: newAges,
      },
    });
  };

  const removeGenderChip = (genderToRemove) => {
    const newGenders = filters.gender.filter(
      (gender) => gender !== genderToRemove
    );
    handleFilterChange({
      target: {
        name: "gender",
        value: newGenders,
      },
    });
  };

  const getGenderLabel = (gender) => {
    return gender === "M" ? "Male" : "Female";
  };

  return (
    <>
      <div className="flex  justify-center mt-6">
        <div className="w-[70%] bg-white dark:bg-slate-800 shadow-lg rounded-2xl p-4 space-y-4">
          {/* First Row - Input Fields */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <input
              type="text"
              ref={inputRef}
              name="country"
              value={filters.country}
              onChange={handleFilterChange}
              placeholder="Country"
              className="w-full px-4 py-2.5 rounded-xl text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-800 transition-all outline-none placeholder:text-gray-500"
            />
            <input
              type="text"
              name="zip_code"
              value={filters.zip_code}
              onChange={handleFilterChange}
              placeholder="Zip Code"
              className="w-full px-4 py-2.5 rounded-xl text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-800 transition-all outline-none placeholder:text-gray-500"
            />
            <input
              type="text"
              name="state"
              value={filters.state}
              onChange={handleFilterChange}
              placeholder="State"
              className="w-full px-4 py-2.5 rounded-xl text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 focus:border-blue-500 dark:focus:border-blue-400 focus:ring-2 focus:ring-blue-200 dark:focus:ring-blue-800 transition-all outline-none placeholder:text-gray-500"
            />
          </div>

          {/* Second Row - Dropdowns and Search */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Age Dropdown */}
            <div className="space-y-2">
              <div ref={dropdownAgeRef} className="relative">
                <button
                  onClick={() => setIsOpenAge(!isOpenAge)}
                  className="w-full h-10 px-4 rounded-xl text-gray-500 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 hover:bg-gray-100 dark:hover:bg-slate-600 transition-all text-left flex justify-between items-center"
                >
                  <span className="text-gray-500">
                    {filters.age.length > 0
                      ? `${filters.age.length} selected`
                      : "Age"}
                  </span>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isOpenAge && (
                  <div className="absolute z-50 w-full mt-2 bg-white dark:bg-slate-800 rounded-xl shadow-lg border border-gray-200 dark:border-slate-600">
                    <ul className="py-2 px-3 space-y-1">
                      {[
                        "13-17",
                        "18-24",
                        "25-34",
                        "25-44",
                        "35-54",
                        "55-64",
                        "65",
                      ].map((age) => (
                        <li
                          key={age}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 dark:hover:bg-slate-700 rounded-lg"
                        >
                          <input
                            className="w-4 h-4 mr-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            name="age"
                            onChange={handleFilterChange}
                            type="checkbox"
                            checked={filters.age.includes(age)}
                            value={age}
                          />
                          <span className="text-gray-700 dark:text-gray-200">
                            {getAgeLabel(age)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {/* Age Chips */}
              {filters.age.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {filters.age.map((age) => (
                    <span
                      key={age}
                      className="inline-flex items-center px-2.5 py-1 rounded-lg text-sm bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200"
                    >
                      {getAgeLabel(age)}
                      <button
                        onClick={() => removeAgeChip(age)}
                        className="ml-1.5 text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
                      >
                        <svg
                          className="w-3.5 h-3.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>

            {/* Gender Dropdown */}
            <div className="space-y-2">
              <div ref={dropdownGenderRef} className="relative">
                <button
                  onClick={() => setIsOpenGender(!isOpenGender)}
                  className="w-full h-10 px-4 rounded-xl text-gray-500 bg-gray-50 dark:bg-slate-700 border border-gray-200 dark:border-slate-600 hover:bg-gray-100 dark:hover:bg-slate-600 transition-all text-left flex justify-between items-center"
                >
                  <span className="text-gray-500">
                    {filters.gender.length > 0
                      ? `${filters.gender.length} selected`
                      : "Gender"}
                  </span>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isOpenGender && (
                  <div className="absolute z-50 w-full mt-2 bg-white dark:bg-slate-800 rounded-xl shadow-lg border border-gray-200 dark:border-slate-600">
                    <ul className="py-2 px-3 space-y-1">
                      {[
                        { value: "M", label: "Male" },
                        { value: "F", label: "Female" },
                      ].map(({ value, label }) => (
                        <li
                          key={value}
                          className="flex items-center px-2 py-1.5 hover:bg-gray-50 dark:hover:bg-slate-700 rounded-lg"
                        >
                          <input
                            className="w-4 h-4 mr-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            name="gender"
                            onChange={handleFilterChange}
                            type="checkbox"
                            checked={filters.gender.includes(value)}
                            value={value}
                          />
                          <span className="text-gray-700 dark:text-gray-200">
                            {label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {/* Gender Chips */}
              {filters.gender.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {filters.gender.map((gender) => (
                    <span
                      key={gender}
                      className="inline-flex items-center px-2.5 py-1 rounded-lg text-sm bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200"
                    >
                      {getGenderLabel(gender)}
                      <button
                        onClick={() => removeGenderChip(gender)}
                        className="ml-1.5 text-blue-600 dark:text-blue-300 hover:text-blue-800 dark:hover:text-blue-100"
                      >
                        <svg
                          className="w-3.5 h-3.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>

            {/* Search Button */}
            <div className="flex items-start">
              <button className="w-full h-10 px-6 bg-theme-purple hover:bg-[#4b25b2] text-white font-medium rounded-xl transition-colors duration-200 flex items-center justify-center space-x-2">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap my-8">
        {applyFilters().length ? (
          applyFilters().map((data, index) =>
            !data.orderDetails.interested_user.length ||
            (data.orderDetails.interested_user.length &&
              data.orderDetails.interested_user[0]?.userId == userId) ? (
              <>
                <div className="lg:w-[25%] md:w-[50%] w-full">
                  <div
                    key={index}
                    className="bg-white dark:bg-[#2B3674] w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
                  >
                    <div className="relative">
                      {data?.data_detail?.category ? (
                        <img
                          className="rounded-2xl"
                          src={`/dataImages/${data?.data_detail?.category} Data.jpg`}
                          alt="contract"
                        />
                      ) : (
                        <img
                          className="rounded-2xl"
                          src={`/dataImages/Multi Data.jpg`}
                          alt="contract"
                        />
                      )}
                      {/* <div className="bg-white dark:bg-slate-400 rounded-full h-8 w-8 absolute right-2 top-3 flex justify-center items-center text-[#856DA7]">
                      <FaRegHeart />
                    </div> */}
                    </div>
                    <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                      <div>
                        <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                          {data.data_name}
                        </h4>
                        <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                          Created Date :{" "}
                          {data.orderDetails.createdAt.split("T")[0]}
                        </p>
                        <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                          Listed Date : {data.createdAt.split("T")[0]}
                        </p>
                        <p className="bg-white text-sm font-medium dark:bg-[#2B3674]">
                          Price :{" "}
                          {data.orderDetails.price
                            ? (
                                parseInt(data.orderDetails.price) / 1e18
                              ).toFixed(6)
                            : 0}{" "}
                          BNB
                        </p>
                        <p className="text-sm uppercase text-[#A3AED0]">
                          {data.data_type}
                        </p>
                      </div>
                      {/* <div className="w-24">
                      <img className="" src="img/Avatar.png" alt="Avatar" />
                    </div> */}
                    </div>
                    <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                      <div>
                        <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                          Size (KB) : {data.data_size}
                        </p>
                      </div>
                      {isConnected ? (
                        <BuyDataPackage
                          address={address}
                          orderId={data.orderDetails.order_id}
                          price={
                            parseInt(data.orderDetails.price) +
                            parseInt(data.orderDetails.fee)
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ) : null
          )
        ) : (
          <>
            <div className="mx-auto">
              <h5 className=" text-center  mb-5  text-xl text-[#7114D2] dark:text-white ">
                No Package Found
              </h5>
              <ImageComponent />
            </div>
          </>
        )}
      </div>
    </>
  );
}
