import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import SearchBar from "../components/SearchBar";
import SideBar from "../components/SideBar";
import orderData from "../img/BlankScreenIcons/orderData.svg";

export default function Dashboard(props) {
  const dispatch = useDispatch();
  let user_type = localStorage.getItem("type");
  let auth = localStorage.getItem("auth");
  let heading = "Orders";
  const [contracts, setContracts] = useState([]);
  const [fileDispute, setFileDispute] = useState({
    showModal: false,
    reason: "",
    message: "",
  });
  const user = useSelector((state) => state.user);
  const getOrdersForBuyer = async () => {
    await dispatch({ type: "loading", payload: true });
    await axios({
      method: "get",
      // url: `https://web3backend.meinstein.ai/api/transactions/getCompletedOrdersForBuyer`,
      url: `https://web3backend.meinstein.ai/api/transactions/getCompletedOrdersForBuyer`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth,
      },
    })
      .then(async (response) => {
        if (response.data.data) {
          await setContracts(response.data.data);
        }

        // await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        //  await dispatch({ type: "loading", payload: false });
        console.log(error.response);
      });

    await axios
      .get(
        `https://web3backend.meinstein.ai/api/payment/${user_type.toLowerCase()}/get_with_paginate?limit=100&currentPage=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.data?.data) {
          if (response?.data?.data?.data)
            await setContracts([...contracts, ...response.data.data.data]);
        }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        await dispatch({ type: "loading", payload: false });
      });
  };

  useEffect(() => {
    getOrdersForBuyer();
  }, []);

  const makeDisputeOnOrder = async (payload) => {
    await dispatch({ type: "loading", payload: true });
    await axios
      .post(
        `https://web3backend.meinstein.ai/api/payment/${user_type.toLowerCase()}/disputeOrder`,
        {
          orderId: fileDispute?.orderId,
          reason: fileDispute.reason,
          message: fileDispute.message,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.code === 200) {
          alert("Dispute raised successfully");

          setFileDispute({
            ...fileDispute,
            showModal: false,
            message: "",
            reason: "",
          });
        }
        await dispatch({ type: "loading", payload: false });
      })
      .catch(async (error) => {
        alert(
          "Sorry, We are unable to proceed your request. Please try again later. If issue persist for long, please contact support."
        );
        await dispatch({ type: "loading", payload: false });
      });
  };

  return (
    <>
      <div className="font-DMSans flex justify-end mx-auto">
        <SideBar />
        <div className="bg-[#f4f7fe] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-dark-bg">
          <div className="mt-[31px] mx-5 text-[#2B3674] dark:text-white">
            <p className="font-Poppins lg:text-xl text-base font-bold text-end mr-5">
              Hey {user}! <span className="text-slate-500 font-normal">|</span>{" "}
              Data Buyer
            </p>
            <SearchBar
              heading={heading}
              themeToggle={props.themeToggle}
              setThemeToggle={props.setThemeToggle}
            />
            <h3 className="text-2xl tracking-[-0.02em] font-bold mt-14">
              Previous Orders
            </h3>
            <div className="flex flex-wrap my-8">
              {contracts.length ? (
                contracts.map((data, index) => (
                  <>
                    <div
                      to={`/contract/download/${
                        data?.orderId || data.orderDetails?.order_id
                      }`}
                      className="lg:w-[25%] md:w-[50%] w-full"
                    >
                      <div
                        key={index}
                        className="bg-white dark:bg-dark-container w-[95%] mx-auto block my-2 rounded-[20px] px-6 py-6"
                      >
                        <Link
                          to={`/contract/download/${
                            data?.orderId || data.orderDetails?.order_id
                          }`}
                        >
                          <div className="relative">
                            {data?.data_detail?.category ? (
                              <img
                                className="rounded-2xl"
                                src={`/dataImages/${data?.data_detail?.category} Data.jpg`}
                                alt="contract"
                              />
                            ) : (
                              <img
                                className="rounded-2xl"
                                src={`/dataImages/Multi Data.jpg`}
                                alt="contract"
                              />
                            )}
                          </div>
                          <div className="tracking-[-0.02em] leading-[30px] mt-4 ml-3 flex justify-between">
                            <div>
                              <h4 className="text-lg text-[#1B2559] dark:text-white font-bold">
                                {data.data_name}
                              </h4>
                              <p className=" text-sm font-medium">
                                Listed Date :{" "}
                                {
                                  (data?.createdAt || data?.created_at)?.split(
                                    "T"
                                  )[0]
                                }
                              </p>
                              <p className="text-sm font-medium ">
                                Price :{" "}
                                {data?.orderDetails?.price || data?.price
                                  ? (
                                      parseInt(
                                        data?.orderDetails?.price || data?.price
                                      ) / 1e18
                                    ).toFixed(6)
                                  : 0}{" "}
                                BNB
                              </p>
                              <p className="text-sm uppercase ">
                                Order Id: {data.orderId}
                              </p>
                            </div>
                          </div>
                          {/* <div className="tracking-[-0.02em] text-sm leading-6 ml-3 mt-5 flex justify-between items-center">
                            <div>
                              <p className="text-[#856DA7] dark:text-blue-600 font-bold">
                                Size (KB) : {data.data_size}
                              </p>
                            </div>
                          </div> */}
                        </Link>
                        {/* <OrderActionBtn
                          status={data?.status}
                          payload={{
                            orderId: fileDispute?.orderId,
                            reason: fileDispute.reason,
                            message: fileDispute.message,
                          }}
                          onClick={() => {
                            switch (data?.status) {
                              case "pending":
                                alert("Please fulfill order from app.");
                                break;

                              case "completed":
                                setFileDispute({
                                  ...fileDispute,
                                  showModal: true,
                                  orderId: `${data?.orderId}`,
                                });
                                break;

                              case "dispute":
                                alert("Need to call api for resolving dispute");
                                break;

                              default:
                                return <></>;
                            }
                          }}
                        /> */}
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="w-full flex flex-col">
                  <p className="text-center  mb-5  text-xl text-[#7114D2] dark:text-white ">
                    No Package Found
                  </p>
                  <img
                    src={orderData}
                    alt="orderDataBlank"
                    className="mx-auto"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {fileDispute.showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-2/6	 my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg p-4 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between pb-4">
                  <h3 className="text-l font-semibold">Create Dispute</h3>
                </div>
                <div className="relative">
                  <div>
                    <label
                      for="category"
                      class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Reason
                    </label>
                    <select
                      id="category"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      onChange={(e) =>
                        setFileDispute({
                          ...fileDispute,
                          reason: e.target.value,
                        })
                      }
                    >
                      <option selected="">Select Reason</option>
                      <option value="1">
                        Insufficient information provided
                      </option>
                      <option value="2">Invalid buyer</option>
                      <option value="3">Policy violation</option>
                    </select>
                  </div>
                  <div className="mt-3">
                    <label
                      for="description"
                      class="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      rows="4"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write dispute description here"
                      onChange={(e) =>
                        setFileDispute({
                          ...fileDispute,
                          message: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-8">
                  <button
                    className="background-transparent font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() =>
                      setFileDispute({
                        ...fileDispute,
                        showModal: false,
                        message: "",
                        reason: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => makeDisputeOnOrder()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
